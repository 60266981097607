/**
 * Functions General Helper.js
 *
 * - a simple Helper wrapper, do all we need to fetch api
 * - and get user datas
 */
import Parse from "parse";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";

export function getEjercicios() {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Ejercicios?limit=1000', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(JSON.parse(xhttp.response));
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getMisRutinas() {
  let userObj = Parse.User.current();
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Rutinas?limit=1000&order=createdAt&where={"clienteId":"'+userObj.id+'"}', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getMisRutinasOnline(level) {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Rutinas?limit=1000&order=createdAt&where={"PlanOnline":"SI", "Level":"'+level+'"}', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}
