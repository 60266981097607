import React from 'react';
// import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Link from './Link.js'
import { slide as Burger } from "react-burger-menu";
import HomeIcon from '../Images/home.svg';
import Avatar from '../Images/Masculino.svg';
import CalIcon from '../Images/calendar.svg';
import TrainIcon from '../Images/skip-rope.svg';
import NutriIcon from '../Images/fish.svg';
import AliadosIcon from '../Images/handshake.svg';
import RecetasIcon from '../Images/fast-food.svg'
import CameraIcon from '../Images/camera.svg';
import ChatIcon from '../Images/chat.svg';
import Parse from "parse";
const userObj = Parse.User.current();
class Menu extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick(param){
    this.setState({isOpen: false})
    this.props.handleClick(param)
  }
  handleStateChange (state) {
   this.setState({isOpen: state.isOpen})
 }
  render() {
    return (
      <Burger
      pageWrapId={"page-wrap"}
      outerContainerId={"App"}
      onStateChange={(state) => this.handleStateChange(state)}
      isOpen={ this.state.isOpen }>
          <div className="profile-work">
              <p className="proile-rating">MI CUENTA</p>
              <Link className="proile-rating" handleClick={this.handleClick} to="/dashboard"><img width="30" src={HomeIcon} alt="Escritorio" /> Escritorio</Link><hr/>
              <Link className="proile-rating" handleClick={this.handleClick} to="/home"><img width="30" src={Avatar} alt="Perfil" /> Perfil</Link><hr/>
              {userObj.get("tipocliente")==="grupo" ? (
                <Link handleClick={this.handleClick} to="/agenda"><img width="30" src={CalIcon} alt="Agenda" /> Agenda de Entrenamientos</Link>
              ) : null}
              <hr/>
              <Link className="proile-rating" handleClick={this.handleClick} to="/entrenamientos"><img width="30" src={TrainIcon} alt="Entrenamientos" /> Entrenamientos Online</Link><hr/>
              <Link className="proile-rating" handleClick={this.handleClick} to="/nutricion"><img width="30" src={NutriIcon} alt="Entrenamientos" /> Mi Nutrición</Link><hr/>
              <Link className="proile-rating" handleClick={this.handleClick} to="/aliados"><img width="30" src={AliadosIcon} alt="Aliados" /> Aliados</Link><hr/>
              <Link className="proile-rating" handleClick={this.handleClick} to="/progresos"><img width="30" src={CameraIcon} alt="Entrenamientos" /> Mis Progresos</Link><hr/>
              <p className="proile-rating">CONTENIDOS</p>
              <Link className="proile-rating" handleClick={this.handleClick} to="/recetas"><img width="30" src={RecetasIcon} alt="Recetas" /> Recetas</Link><hr/>
              <p className="proile-rating">SOPORTE</p>
              <Link className="proile-rating" handleClick={this.handleClick} to="/chat"><img width="30" src={ChatIcon} alt="Chat" /> Escríbenos</Link><hr/>
          </div>
      </Burger>
    );
  }
}

export default Menu;
