import React from 'react';
import logoBlanco from "./Images/logoBlancox3.png";
import MasculinoAvatar from './Images/Masculino.svg';
import FemeninoAvatar from './Images/Femenino.svg';
import './App.css';
import { getRecetas, getAlimentos, getTips } from "./actions/AgendaHelper";
import ReactHtmlParser from 'react-html-parser';
import Modal from 'react-responsive-modal';
import Parse from "parse";
import ItemsCarousel from 'react-items-carousel';
import FoodIcon from './Images/diet.svg';
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";
const modalstyle = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "center"
  }
};
const modalstyle2 = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "justify",
    'padding': 20
  }
};

export default class Recetas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      asistencias: [],
      myranking: 0,
      loading: true,
      modal: false,
      modal2: false,
      modal3: false,
      recetas: [],
      recetaTitle: "",
      recetaBody: "",
      menu: [],
      activeItemIndex: 0,
      tips: [],
      modalstyle: null,
      carbos: ""
    };
    this.verReceta = this.verReceta.bind(this)
    this.verTip = this.verTip.bind(this)
  }



  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }
  verTip(tip){
    this.setState({
      modal3: true,
      recetaTitle: tip.title,
      modalstyle: modalstyle2,
      recetaBody: ReactHtmlParser(tip.cuerpo.replace(/\\"/g, '"'))
    });
  }

  verReceta(receta) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    var match = receta.cuerpo.match(regExp);

    if(match && match[2])
      match[2] = match[2].replace("</a></p>", "");

     if (match && match[2].length === 11) {
      receta.cuerpo = '<div style="position:relative;padding-bottom: 56.25%;padding-top: 25px;height: 0;" className="videoWrapper">\
        <iframe width="100%" height="90%" src="//www.youtube.com/embed/'+match[2]+'" frameborder="0" allowfullscreen></iframe>\
      </div>';
    }
    this.setState({
      modal3: true,
      recetaTitle: receta.title,
      modalstyle: modalstyle,
      recetaBody: ReactHtmlParser(receta.cuerpo.replace(/\\"/g, '"'))
    });
    // this.toggle();
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name);
    console.log(value);
    this.setState({
      [name]: value
    });
  }

  componentWillReceiveProps(nextProps){
    let user = nextProps.user;
    this.setState({
      fullname: user.fullname,
      email: user.email,
      nit: user.nit,
      telefono: user.telefono,
      fechainicio: user.fechainicio,
      fechacorte: user.fechacorte,
      objetivo: user.objetivo,
      user: user
    })
  }

  componentDidMount() {
    if(this.props && this.props.user){
      let user = this.props.user;
      this.setState({
        fullname: user.fullname,
        email: user.email,
        nit: user.nit,
        telefono: user.telefono,
        fechainicio: user.fechainicio,
        fechacorte: user.fechacorte,
        objetivo: user.objetivo,
        user: user
      })
    }
    // this.setState({
    //   email: user.email,
    //   nit: user.nit,
    //   telefono: user.telefono,
    //   fechainicio: user.fechainicio,
    //   fechacorte: user.fechacorte,
    //   objetivo: user.objetivo,
    //   fullname: user.fullname,
    //   user: user,
    //   sexo: user.sexo,
    // })



    getAlimentos().then(results => {
      let alimentos = JSON.parse(results);
      let carbos = [], protes = [], fats = [];
      for (var i = 0; i < alimentos.results.length; i++) {
        let alimento = alimentos.results[i];
        if(alimento.macronutriente.includes("Carbohidratos"))
          if(alimento.ig==="Bajo IG")
            carbos.push('<span class="success">'+alimento.nombre+'</span>')
          else if (alimento.ig==="Medio IG")
            carbos.push('<span class="warning">'+alimento.nombre+'</span>')
          else
            carbos.push('<span class="danger">'+alimento.nombre+'</span>')

        if(alimento.macronutriente.includes("Proteinas"))
          protes.push(alimento.nombre)

        if(alimento.macronutriente.includes("Grasas"))
          fats.push(alimento.nombre)
      }
      this.setState({
        carbos: carbos.join(', '),
        protes: protes.join(', '),
        fats: fats.join(', '),
        alimentos: alimentos
      })
    })
    getTips("Nutricion").then(tips => {
      let tipsAr = JSON.parse(tips);
      this.setState({ tips: tipsAr.results})
    })

    getRecetas().then(results => {
      let result = JSON.parse(results);
      this.setState({
        loading: false,
        recetas: result.results
      });
    });

  }

  getvideo(id){
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    var match = id.match(regExp);
     if (match && match[2].length === 11) {
      return (
        <div>
          <iframe title="iframe" width="300" height="250" src={"//www.youtube.com/embed/"+match[2]} frameborder="0" allowfullscreen></iframe>
        </div>
      )
    }
  }
  render() {


    let fechacorte = this.state.user && this.state.user.fechacorte_real ? this.state.user.fechacorte_real.iso : "";
    const fechaSplited = fechacorte.split("T");
    const fechaArray = fechaSplited[0].split("-");
    fechacorte = fechaArray[2] + "/" + parseInt(fechaArray[1]) + "/" + fechaArray[0];

    let avatar = MasculinoAvatar

    if(this.state.sexo === "Femenino")
      avatar = FemeninoAvatar

    if(this.state.user && this.state.user.avatar)
      avatar = this.state.user.avatar

    return (
      <div id="page-wrap">
        <header className="App-header">
          <img src={logoBlanco} className="App-logo" alt="logo" />
        </header>
        <div className="container emp-profile">

                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-img">
                            <img src={avatar} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head">
                          <h5>
                              {this.state.fullname}
                          </h5>
                          <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
                            Cerrar Sesión
                          </button>
                          <br/>
                          <h6 style={{cursor:'pointer'}} onClick={() => this.setState({modal: true})}>
                                {this.state.objetivo && this.state.objetivo.length ? this.state.objetivo : "No has ingresado tu objetivo general"}
                          </h6>
                          <Modal open={this.state.modal} onClose={() => this.setState({modal: false})} center>
                            <h5>Objetivo General</h5>
                            <div className="form-group shadow-textarea">
                              <label for="exampleFormControlTextarea6">Ingresa una descripción de tu objetivo general con VES</label>
                              <textarea className="form-control z-depth-1" name="objetivo" onChange={this.handleInputChange.bind(this)} id="exampleFormControlTextarea6" rows="3" placeholder="Mejorar mi salud...">
                                {this.state.objetivo}
                              </textarea>
                              <br/>
                              <button onClick={()=>{
                                let user = Parse.User.current();
                                if(this.state.objetivo.length){
                                  user.set("objetivo", this.state.objetivo);
                                  user.save().then(()=>{
                                    this.props.getUserData()
                                  });
                                  this.setState({modal: false})
                                }else{
                                  alert("Ingrese su objetivo por favor")
                                }

                              }} type="button" className="btn btn-outline-success">Guardar objetivo</button>
                            </div>
                          </Modal>
                          <p className="proile-rating">NÚMERO DE ENTRENAMIENTOS REALIZADOS : <span>{this.state.asistencias.length}</span></p>
                          <button onClick={() => this.setState({modal2: true})} className="btn btn-outline-success">Ver Guía de alimentos</button>
                          <Modal styles={modalstyle2} open={this.state.modal2} onClose={() => this.setState({modal2: false})} center>
                            <h5>Guia Nutricional</h5>
                            <div className="form-group shadow-textarea">
                            <div class="pull-right">
                              <div class="btn-group flex-wrap">
                                <button type="button" class="btn btn-danger btn-filter" data-target="pagado" onClick={() => this.filtrar("Vegetales")} >Vegetales</button>
                                <button type="button" class="btn btn-danger btn-filter" data-target="pendiente" onClick={() => this.filtrar("Complejos")} >Complejos</button>
                                <button type="button" class="btn btn-danger btn-filter" data-target="cancelado" onClick={() => this.filtrar("Simple")} >Simple</button>
                                <button type="button" class="btn btn-danger btn-filter" data-target="all" onClick={() => this.filtrar("Frutas")} >Frutas</button>
                                <button type="button" class="btn btn-success btn-filter" data-target="pagado" onClick={() => this.filtrar("Lacteos")} >Lácteos</button>
                                <button type="button" class="btn btn-success btn-filter" data-target="pendiente" onClick={() => this.filtrar("Animal")} >Animal</button>
                                <button type="button" class="btn btn-warning btn-filter" data-target="cancelado" onClick={() => this.filtrar("Aceites")} >Aceites</button>
                                <button type="button" class="btn btn-warning btn-filter" data-target="pagado" onClick={() => this.filtrar("Frutos Secos")} >Frutos Secos</button>
                                <button type="button" class="btn btn-warning btn-filter" data-target="all" onClick={() => this.filtrar("Otros")} >Otros</button>
                                <button type="button" class="btn btn-info btn-filter" data-target="all" onClick={() => this.filtrar("Todos")} >Todos</button>
                              </div>
                            </div>
                            <table class="table table-striped">
                              <thead  class="thead-dark">
                                <tr>
                                  <th>Macronutriente</th>
                                  <th>Alimentos</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td><span className="danger">Carbohidratos</span></td>
                                  <td>{ReactHtmlParser(this.state.carbos.replace(/\\"/g, '"'))}</td>
                                </tr>
                                <tr>
                                  <td><span className="success">Proteinas</span></td>
                                  <td>{this.state.protes}</td>
                                </tr>
                                <tr>
                                  <td><span className="warning">Grasas</span></td>
                                  <td>{this.state.fats}</td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                          </Modal>
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item">
                                  <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Recetas</a>
                              </li>
                          </ul>
                        </div>
                        <div className="col-md-12">
                          <p className="category">
                          Aquí encontrarás deliciosas recetas que te ayudarán a mantenerte saludable y en forma.
                          </p>
                        </div>
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>
                <div className="row">
                    {this.state.recetas.map(receta => (
                      <div className="col-md-4">
                        <div className="card">
                          <img src={receta.pictures ? receta.pictures[0].src : null} class="card-img-top" alt={receta.title} />
                          <div className="card-body">
                            <h5 className="card-title">{receta.title}</h5>
                            <p>
                            {receta.categoria==="Saludable" ? (<span class="badge badge-warning">Saludable</span>):(null)}
                            {receta.categoria==="General" ? (<span class="badge badge-secondary">General</span>):(null)}
                            {receta.categoria==="Baja en grasa" ? (<span class="badge badge-success">Baja en grasa</span>):(null)}
                            {receta.categoria==="Vegetariana" ? (<span class="badge badge-success">Vegetariana</span>):(null)}
                            {receta.categoria==="Baja en carbohidratos" ? (<span class="badge badge-primary">Baja en carbohidratos</span>):(null)}
                            {receta.categoria==="Sin Carbohidratos" ? (<span class="badge badge-info">Sin Carbohidratos</span>):(null)}
                            </p>
                            <button onClick={() => {
                              this.verReceta(receta)
                            }} className="btn btn-outline-success" >Ver Receta</button>
                          </div>
                        </div>
                        <br/>
                      </div>
                    ))}
                  <div className="col-md-12">
                    <h5 className="card-title">TIPS DE ALIMENTACIÓN</h5>
                  </div>
                  <div style={{"padding":"0 60px","maxWidth":800,"margin":"0 auto", width: "100vw"}}>
                  {window.innerWidth <= 800 ? (
                    <ItemsCarousel
                      gutter={12}
                      activePosition={'center'}
                      chevronWidth={60}
                      numberOfCards={1}
                      slidesToScroll={1}
                      outsideChevron={true}
                      showSlither={false}
                      firstAndLastGutter={false}
                      activeItemIndex={this.state.activeItemIndex}
                      requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                      rightChevron={<button className="btn btn-outline-success">{'>'}</button>}
                      leftChevron={<button className="btn btn-outline-success">{'<'}</button>}
                    >
                      {this.state.tips &&  this.state.tips.map(tip =>
                        <div
                          key={tip.objectId}
                          style={{
                            height: 350,
                            background: "#EEE",
                          }}
                        >

                          <h5 className="text-center"><br/><img src={FoodIcon} width="60" alt={tip.title} /></h5>
                          <h5 className="text-center"><br/>{tip.title}</h5>
                          <p style={{
                            padding: 10,
                            fontSize: 12,
                          }} >
                            {tip.resumen}
                            <br/>
                            {tip.cuerpo ? (<button onClick={() => {
                              this.verTip(tip)
                            }} className="btn btn-outline-success">Ver más</button>) : (null)}
                          </p>
                        </div>
                      )}
                    </ItemsCarousel>
                  ):(
                    <ItemsCarousel
                      gutter={12}
                      activePosition={'center'}
                      chevronWidth={60}
                      numberOfCards={2}
                      slidesToScroll={2}
                      outsideChevron={true}
                      showSlither={false}
                      firstAndLastGutter={false}
                      activeItemIndex={this.state.activeItemIndex}
                      requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                      rightChevron={<button className="btn btn-outline-success">{'>'}</button>}
                      leftChevron={<button className="btn btn-outline-success">{'<'}</button>}
                    >
                      {this.state.tips &&  this.state.tips.map(tip =>
                        <div
                          key={tip.objectId}
                          style={{
                            height: 350,
                            background: "#EEE",
                          }}
                        >

                          <h5 className="text-center"><br/><img src={FoodIcon} width="60" alt={tip.title} /></h5>
                          <h5 className="text-center"><br/>{tip.title}</h5>
                          <p style={{
                            padding: 10,
                            fontSize: 12,
                          }} >
                            {tip.resumen}
                            <br/>
                            {tip.cuerpo ? (<button onClick={() => {
                              this.verTip(tip)
                            }} className="btn btn-outline-success">Ver más</button>) : (null)}
                          </p>
                        </div>
                      )}
                    </ItemsCarousel>
                  )}
                  </div>
                  <br/>
                </div>
          </div>
          <Modal styles={this.state.modalstyle} open={this.state.modal3} onClose={() => this.setState({modal3: false})} center>
          <h5 className="card-title">{this.state.recetaTitle}</h5>
          <br/>
          {this.state.recetaBody}
          </Modal>
        </div>

    );
  }
}
