/**
 * Functions General Helper.js
 *
 * - a simple Helper wrapper, do all we need to fetch api
 * - and get user datas
 */
import Parse from "parse";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";

export function getUserComments() {
  let userObj = Parse.User.current();
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Comentarios?limit=1000&order=createdAt&where={"usuarioId":"'+userObj.id+'"}', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status===200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}
export function getAsistenciasRanking() {
  return new Promise((resolve, reject) => {
    var xhttp1 = new XMLHttpRequest();
    var xhttp = new XMLHttpRequest();
    var date = new Date();
    var month = String(date.getMonth() + 1);
    var day = String(date.getDate());
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setHours(-1);
    var firstmonth = String(firstDay.getMonth() + 1)
    if (firstmonth.length < 2) 
      firstmonth = '-0' + String(firstDay.getMonth() + 1);
    else
      firstmonth = '-' + String(firstDay.getMonth() + 1);
    var firstDayString = String(firstDay.getFullYear()) + firstmonth + '-' + String(firstDay.getDate()) + 'T23:00:00.249Z';

    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var lastmonth = String(lastDay.getMonth() + 1)
    
    if (lastmonth.length < 2) 
      lastmonth = '-0' + String(lastDay.getMonth() + 1);
    else
      lastmonth = '-' + String(lastDay.getMonth() + 1);

    var lastDayString = String(lastDay.getFullYear()) + lastmonth+ '-' + String(lastDay.getDate()) + 'T23:00:00.249Z';
    
    xhttp1.open("GET", 'https://miembros.vescolombia.com.co/parse/users/?where={"tipocliente":"grupo", "fechacorte_real":{"$gte":{"__type":"Date","iso":"'+date.toISOString()+'"}}}', true);

    xhttp1.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp1.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp1.onload = function() {
      if (xhttp1.status===200) {
        let results = JSON.parse(xhttp1.response);
        for (var i = 0; i < results.results.length; i++) {
          var result = results.results[i];
          if(!result.fullname.includes("Fitpal") && !result.fullname.includes("SAVY")){
            xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Asistencias?where={"userId":"'+result.objectId+'", "fechareal":{"$gte":{"__type":"Date","iso":"'+firstDayString+'"}, "$lte":{"__type":"Date","iso":"'+lastDayString+'"}}}', false);
            console.log('https://miembros.vescolombia.com.co/parse/classes/Asistencias?where={"userId":"'+result.objectId+'", "fechareal":{"$gte":{"__type":"Date","iso":"'+firstDayString+'"}, "$lte":{"__type":"Date","iso":"'+lastDayString+'"}}}');
            
            xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
            xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
            xhttp.send();
            if (xhttp.status === 200) {
              let asistencias = JSON.parse(xhttp.response);
              results.results[i]["asistencias"] = asistencias.results.length;
            }else {
              results.results[i]["asistencias"] = 0;
              // reject(Error(xhttp1.statusText));
            }
          }
        }
        resolve(JSON.stringify(results));

        // xhttp.onload = function() {
        //   if (xhttp.status===200) {
        //     resolve(xhttp.response);
        //   } else {
        //     reject(Error(xhttp.statusText));
        //   }
        // };

      } else {
        reject(Error(xhttp1.statusText));
      }
    };
    xhttp1.send();
  });
}
export function getTips() {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Tips', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status===200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getUserFat() {
  let userObj = Parse.User.current();
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Medidas?limit=1000&order=createdAt&where={"UsuairioId":"'+userObj.id+'"}', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status===200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getUserAsistencias() {
  let userObj = Parse.User.current();
  let parts = userObj.get("fechainicio").split("/");
  var fechainicio = parts[2] + "-" + parts[1] + "-" + parts[0];
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    if (userObj.get("tipocliente")!=="conjunto") {
      xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Asistencias?limit=10&order=-createdAt&where={"userId":"'+userObj.id+'", "fechareal":{"$gte":{"__type":"Date","iso":"'+fechainicio+'T23:00:00.249Z"}, "$lte":{"__type":"Date","iso":"'+userObj.get("fechacorte_real")+'"}}}', true);
    }else {
      xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Asistencias?limit=10&order=-createdAt&where={"userId":"'+userObj.id+'"}', true);
    }

    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status===200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function setUserObjetivos(objetivo_corto, objetivo_largo, objetivo_mediano) {
  let userObj = Parse.User.current();
  userObj = userObj._toFullJSON();
  console.log(userObj);
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open(
      "PUT",
      "https://miembros.vescolombia.com.co/parse/users/" + userObj.objectId,
      true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.setRequestHeader("X-Parse-Session-Token", userObj.sessionToken);
    xhttp.onload = function() {
      // if (xhttp.status===200) {
      //   // alert("Tus objetivos han sido actualizados.");
      // } else {
      //   // alert("ERROR! Tus objetivos NO fueron actualizados");
      // }
      if (xhttp.status===200) {
        resolve(true);
      } else {
        reject(false);
      }
    };
    //sessionToken
    xhttp.send(
      '{"objetivo_corto":"' +
        objetivo_corto +
        '", "objetivo_mediano":"' +
        objetivo_mediano +
        '", "objetivo_largo":"' +
        objetivo_largo +
        '"}'
    );
  });
}

export function setUserInfo(fullname, telefono, email, nit, perfilCliente, sexo) {
  let userObj = Parse.User.current();
  userObj = userObj._toFullJSON();
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open(
      "PUT",
      "https://miembros.vescolombia.com.co/parse/users/" + userObj.objectId,
      true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.setRequestHeader("X-Parse-Session-Token", userObj.sessionToken);
    xhttp.onload = function() {
      if (xhttp.status===200) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    //sessionToken
    xhttp.send(
      '{"fullname":"' +
        fullname +
        '", "email":"' +
        email +
        '", "nit":"' +
        nit +
        '","perfilCliente":"' +
        perfilCliente +
        '","sexo":"' +
        sexo +
        '", "telefono":"' +
        telefono +
        '"}'
    );
  });

}
