import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
export default class CalificarForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calificacion: null,
      comentario: null,
      fechaCalificacion: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(date) {
    this.setState({
      fechaCalificacion: date
    });
  }
  handleOptionChange(changeEvent) {
  this.setState({
    calificacion: changeEvent.target.value
  });
}
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  handleSubmit() {
    const usuario = this.props.usuario
    const {calificacion} = this.state;
    const {comentario} = this.state;
    const {fechaCalificacion} = this.state;

    if (usuario && usuario.conjunto){
      if (calificacion && fechaCalificacion) {
        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", 'https://miembros.vescolombia.com.co/parse/classes/Asistencias/', true);
        xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
        xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
        xhttp.onload = function() {
          if (xhttp.status === 200) {
            alert("Calificación enviada");
            window.location.reload()
          } else {
            alert(xhttp.statusText);
          }
        };
        this.setState({
          userId: usuario.objectId
        })
        xhttp.send(JSON.stringify(this.state));
      } else {
        alert("Los campos Fecha y Calificación son obligatorios");
      }
    }else {
      if (calificacion && comentario) {
        var xhttp = new XMLHttpRequest();
        xhttp.open("PUT", 'https://miembros.vescolombia.com.co/parse/classes/Asistencias/'+this.props.asistencia.objectId, true);
        xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
        xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
        xhttp.onload = function() {
          if (xhttp.status === 200) {
            alert("Calificación enviada");
          } else {
            alert(xhttp.statusText);
          }
        };
        xhttp.send(JSON.stringify(this.state));
      } else {
        alert("Los campos Comentarios y Calificación son obligatorios");
      }
    }

  }
  render() {
    const usuario = this.props.usuario
    return (
      <form>
        {usuario && usuario.conjunto ? (
          <div>
            <h5>Fecha</h5>
            <DatePicker
              className="form-control"
              selected={this.state.fechaCalificacion}
              onChange={this.handleChange}
              maxDate={new Date()}
              dateFormat="yyyy/MM/dd"
            />
          </div>
        ) : null}

          <h5>Calificación</h5>
          <div>
            <input
              type="radio"
              id="exampleCustomRadio"
              name="customRadio"
              label="Excelente"
              value="Excelente"
              onChange={this.handleOptionChange.bind(this)}
            /> <h6>Excelente</h6>
            <input
              type="radio"
              id="exampleCustomRadio2"
              name="customRadio"
              label="Bueno"
              value="Bueno"
              onChange={this.handleOptionChange.bind(this)}
            /> <h6>Bueno</h6>
            <input
              type="radio"
              id="exampleCustomRadio3"
              name="customRadio"
              label="Medio"
              value="Medio"
              onChange={this.handleOptionChange.bind(this)}
            /> <h6>Medio</h6>
            <input
              type="radio"
              id="exampleCustomRadio4"
              name="customRadio"
              label="Deficiente"
              value="Deficiente"
              onChange={this.handleOptionChange.bind(this)}
            /> <h6>Deficiente</h6>
          </div>
          <h5>Comentarios</h5>
          <p>
          Escríbenos tus comentarios a cerca de tu último entrenamiento. Para nosotros es importante conocer lo
          que te gusta y tus preferencias. Tus comentarios siempre serán leídos para poder brindarte una mejor sesión
          en tu siguiente entrenamiento. También puedes contarnos los ejercicios de tu preferencia para que planeemos tus
          rutinas haciendo uso de ellos y te diviertas entrenando con nosotros.
        </p>
          <textarea style={{minWidth: 300}} className="form-control z-depth-1" rows="6" type="textarea" name="comentario" id="exampleText" onChange={this.handleInputChange.bind(this)}>
          </textarea>
        <a href="#" className="btn btn-outline-success" onClick={()=>{this.handleSubmit()}} >Calificar</a>
      </form>
    );
  }
}
