import React from 'react';
class Link extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props)

    // The `bind` is necessary to make `this` work within the `handleClick`
    this.handleClick = this.handleClick.bind(this)
  }

  isLeftClickEvent(event) {
    return event.button === 0;
  }

  isModifiedEvent(event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
  }
  handleClick(event) {
    if (event.defaultPrevented ||
        this.isModifiedEvent(event) ||
        !this.isLeftClickEvent(event)) {
      return
    }

    event.preventDefault()
    // It only makes sense for an application to have one history, so we can
    // make it global
    // hist.push(this.props.to)
    this.props.handleClick(this.props.to)
  }

  render() {
    var props = Object.assign({}, this.props)

    // delete this.props.to

    props.onClick = this.handleClick
    props.href = this.props.to.pathname + (this.props.to.search || '')

    return <a {...props}>{props.children}</a>
  }
}

export default Link;
