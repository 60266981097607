import React from 'react';
import logoBlanco from "./Images/logoBlancox3.png";
import MasculinoAvatar from './Images/Masculino.svg';
import FemeninoAvatar from './Images/Femenino.svg';
import './App.css';
import { getUserBooking, cancelarBooking } from "./actions/AgendaHelper";
import {  getUserAsistencias } from "./actions/DashboardHelper";
import CalificarForm from "./components/Forms/CalificarForm";
import AgendarForm from "./components/Forms/AgendarForm";
import Modal from 'react-responsive-modal';
import Parse from "parse";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";

export default class Agenda extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      asistencias: [],
      booking: [],
      startDate: new Date(),
      ranking: [],
      myranking: 0,
      loading: true,
      modal2: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleCancel(asistencia, fecha, hora){
    const msnj = cancelarBooking(asistencia, fecha, hora);
    alert(msnj)
  }
  handleChange(date) {
    this.setState({
      startDate: date
    });
  }
  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        // window.location.reload();
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        // window.location.reload();
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }
  componentWillReceiveProps(nextProps){
    let user = nextProps.user;
    this.setState({
      fullname: user.fullname,
      email: user.email,
      nit: user.nit,
      telefono: user.telefono,
      fechainicio: user.fechainicio,
      fechacorte: user.fechacorte,
      objetivo: user.objetivo,
      user: user,
      sexo: user.sexo,
      loading: false,
      loadingAvatar: false,
      editMode: false
    });
  }
  componentDidMount() {
    if(this.props && this.props.user){
      let user = this.props.user;
      this.setState({
        fullname: user.fullname,
        email: user.email,
        nit: user.nit,
        telefono: user.telefono,
        fechainicio: user.fechainicio,
        fechacorte: user.fechacorte,
        objetivo: user.objetivo,
        user: user
      })
    }

    getUserBooking().then(results => {
      let result = JSON.parse(results);
      let booking = [];
      for (var i = 0; i < result.results.length; i++) {
        booking.push(result.results[i]);
      }
      this.setState({ booking });
    });
    getUserAsistencias().then(results => {
      let result = JSON.parse(results);
      let asistencias = [];
      for (var i = 0; i < result.results.length; i++) {
        asistencias.push(result.results[i]);
      }
      this.setState({ ultimaAsistencia: asistencias[0] });
      this.setState({ asistencias });
    });
    // getAsistenciasRanking().then(results => {
    //   let userObj = Parse.User.current();
    //   let resultados = JSON.parse(results);
    //   resultados.results.sort(function (a, b) {
    //     return a.asistencias - b.asistencias;
    //   });
    //   this.setState({ranking: resultados.results.reverse()}, () => {
    //     let positionR = this.state.ranking.findIndex(p => p.objectId === userObj.id)
    //     if(positionR !== -1)
    //       this.setState({myranking: positionR});
    //   });
    //
    // });
  }
  render() {

    var ultimaAsistencia = this.state.ultimaAsistencia ?
    ( <span>{this.state.ultimaAsistencia.fecha}</span> ) :
    ( <span>Usted no tiene asistencias</span> );

    let fechacorte = this.state.user ? this.state.user.fechacorte_real.iso : "";
    const fechaSplited = fechacorte.split("T");
    const fechaArray = fechaSplited[0].split("-");
    fechacorte = fechaArray[2] + "/" + parseInt(fechaArray[1]) + "/" + fechaArray[0];

    let fechacorteMax = this.state.user
      ? new Date(fechaArray[0], fechaArray[1], fechaArray[2])
      : new Date();
    let avatar = MasculinoAvatar
    if(this.state.sexo === "Femenino")
      avatar = FemeninoAvatar

    if(this.state.user && this.state.user.avatar)
      avatar = this.state.user.avatar

    return (
      <div id="page-wrap">
        <header className="App-header">
          <img src={logoBlanco} className="App-logo" alt="logo" />
        </header>
        <div className="container emp-profile">

                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-img">
                            <img src={avatar} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head">
                          <h5>
                              {this.state.fullname}
                          </h5>
                          <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
                            Cerrar Sesión
                          </button>
                          <br/>
                          <h6 style={{cursor:'pointer'}} onClick={() => this.setState({modal: true})}>
                                {this.state.objetivo && this.state.objetivo.length ? this.state.objetivo : "No has ingresado tu objetivo general"}
                          </h6>
                          <p className="proile-rating">NÚMERO DE ENTRENAMIENTOS REALIZADOS : <span>{this.state.asistencias.length}</span></p>
                          <button className="btn btn-outline-success"  onClick={() => this.setState({modal2: true})} >Califica tu último entrenamiento</button>
                          <Modal open={this.state.modal2} onClose={() => this.setState({modal2: false})} center>
                            <h5>Califica tu último entrnamiento:<br/> {ultimaAsistencia}</h5>
                            <div className="form-group shadow-textarea">
                              <CalificarForm asistencia={this.state.ultimaAsistencia} />
                            </div>
                          </Modal>
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item">
                                  <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Agenda</a>
                              </li>
                          </ul>
                        </div>
                        <div className="col-md-12">
                          <p className="category">
                            Organiza tus próximos entrenamientos y recuerda que tienes hasta 4 horas para cancelarlos.
                          </p>
                        </div>
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>
              </div>
              <div className="container emp-profile">
                <div className="row">
                    <div className="col-md-12">
                    <h4>Fecha de corte: {fechacorte}</h4>
                    <h4>Condiciones</h4>
                    <p>
                      Puedes agendar tus entrenamientos hasta 2 horas antes de que éstos comiencen.<br />
                      Tienes hasta 4 horas antes del inicio de la hora agendada para cancelar.<br />
                      Si agendas, no cancelas dentro del plazo de 4 horas y no asistes a tu clase programada, se te descontará un (1) día de tu PLAN.<br />
                      En caso de lluvia, en el día y hora agendado, colocaremos un (1) día más en tu PLAN.<br />
                    </p>
                      <div className="card">
                        <div className="card-body">
                          <AgendarForm fechacorteMax={fechacorteMax}/>
                        </div>
                      </div>
                      <br/>
                    </div>
                  </div>
                </div>
                <div className="container emp-profile">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title">Mis clases agendadas</h5>
                              <h6 className="card-subtitle mb-2 text-muted">Solo podrás cancelar hasta 4 horas antes del inicio de la clase</h6>
                              <table className="table">
                              <thead className="text-primary">
                                <tr>
                                  <th>Fecha</th>
                                  <th>Clase</th>
                                  <th>Acciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.booking.map(asistencia => (
                                  <tr key={asistencia.objectId}>
                                    <td>{asistencia.fechafake}</td>
                                    <td>{asistencia.clase}</td>
                                    <td>
                                      <button
                                        onClick={
                                          ()=>{
                                            this.handleCancel( asistencia.objectId, asistencia.fechafake, asistencia.horaclase )
                                          }
                                        }
                                        className="btn btn-outline-danger"
                                      >
                                        Cancelar
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>        
    );
  }
}
