import React from 'react';
import logoBlanco from "./Images/logoBlancox3.png";
import MasculinoAvatar from './Images/Masculino.svg';
import FemeninoAvatar from './Images/Femenino.svg';
import ItemsCarousel from 'react-items-carousel';
import GoldIcon from './Images/gold.svg';
import SilverIcon from './Images/silver.svg';
import BronceIcon from './Images/bronce.svg';
import yogaIcon from './Images/yogamat.svg';
import nutricionIcon from "./Images/diet.svg";
import saludableIcon from "./Images/heartbeat.svg";
import entrenamientoIcon from "./Images/dumbbell.svg";
import podioIcon from "./Images/podium.svg"
import menteIcon from "./Images/idea.svg";
import './App.css';
import { getUserFat, getUserComments, getUserAsistencias,
getAsistenciasRanking } from "./actions/DashboardHelper";
import { getTips } from "./actions/AgendaHelper";
import AgendarForm from "./components/Forms/AgendarForm";
import CalificarForm from "./components/Forms/CalificarForm";
import ReactHtmlParser from 'react-html-parser';
import Modal from 'react-responsive-modal';
import {Dropbox} from 'dropbox'
import Parse from "parse";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";
const accessToken = 'DC3q4Q6sIwAAAAAAAAAAC6GtnNTJCqCERh-ap2KrdQSiDkBJwP-ROA7a7KTGd7Qc';
const dbx = new Dropbox({
  accessToken,
  fetch
});
const modalstyle2 = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "justify",
    'padding': 20
  }
};

const userObj = Parse.User.current();

export default class Escritorio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullname: "",
      email: "",
      nit: "",
      telefono: "",
      fechainicio: "",
      fechacorte: "",
      myfat: [],
      mycomments: [],
      modal: false,
      modal2: false,
      medidaTitle: null,
      bone_mass: "",
      bmi: "",
      basal_metabolic_rate: "",
      BodyFat: "",
      Peso: "",
      skeletal_muscle: "",
      viceral_fat: "",
      comentariosNutricion: "",
      comentarios_deportologo: "",
      objetivo: "",
      myranking: 0,
      user: null,
      editMode: false,
      asistencias:[],
      ranking: [],
      ultimaAsistencia: null,
      loading: false,
      activeItemIndex: 0,
      tips: [],
      modalstyle: null,
      modal3: false,
      comentario: "",
      addpic: null,
      tag: "",
      comunidad: null,
      mycomunidad: null,
      admin: null,
      opened: null
    };
    this.verMedidas = this.verMedidas.bind(this);
    this.verTip = this.verTip.bind(this)
  }
  verTip(tip){
    this.setState({
      modal3: true,
      recetaTitle: tip.title,
      modalstyle: modalstyle2,
      recetaBody: ReactHtmlParser(tip.cuerpo.replace(/\\"/g, '"'))
    });
  }
  verMedidas(medidas){
    this.setState(
      {
        medidaTitle: "Medición del " + medidas.Fecha,
        Peso: medidas.Peso,
        bone_mass: medidas.bone_mass,
        bmi: medidas.bmi,
        basal_metabolic_rate: medidas.basal_metabolic_rate,
        BodyFat: medidas.BodyFat,
        skeletal_muscle: medidas.skeletal_muscle,
        viceral_fat: medidas.viceral_fat,
        comentariosNutricion: medidas.comentariosNutricion
          ? ReactHtmlParser(medidas.comentariosNutricion.replace(/\\"/g, '"'))
          : "",
        comentarios_deportologo: medidas.comentarios_deportologo
          ? ReactHtmlParser(
              medidas.comentarios_deportologo.replace(/\\"/g, '"')
            )
          : ""
      },
      function(){
        this.setState({modal:true})
      }
    )
  }
  responder() {
    const GameScore = Parse.Object.extend("Comunidad");
    const gameScore = new GameScore();
    let userObj = Parse.User.current();
    userObj = userObj._toFullJSON();
    gameScore.set("userId", userObj.objectId);
    gameScore.set("comentario", this.state.comentario);
    gameScore.set("padre", this.state.opened);
    gameScore.save()
    .then((gameScore) => {
      alert('Respuesta enviada');
      window.location.reload();
    }, (error) => {
      alert('Verifique su conexión, no se pudo enviar la respuesta');
    });
  }
  publicar() {
    var fileInput = document.getElementById('fotoPerfil');
    var file = fileInput.files[0] ? fileInput.files[0] : null;
    let userObj = Parse.User.current();
    userObj = userObj._toFullJSON();
    if(file)
    dbx.filesUpload({path: '/' + file.name, contents: file, mode: 'overwrite'})
    .then(function(response) {
      console.log(response.path_display);
      dbx.sharingCreateSharedLinkWithSettings({path: response.path_display})
      .then(function(responselink) {
         const GameScore = Parse.Object.extend("Comunidad");
         const gameScore = new GameScore();

         gameScore.set("userId", userObj.objectId);
         gameScore.set("comentario", this.state.comentario);
         gameScore.set("tag", this.state.tag);
         gameScore.set("archivo", responselink.url.replace('dl=0', 'raw=1'));
         gameScore.save()
         .then((gameScore) => {
           alert('Comentario creado');
           window.location.reload();
         }, (error) => {
           alert('Verifique su conexión, no se pudo enviar el comentario');
         });

       })
       .catch(function(error) {
         console.log(error);
       });
    })
    .catch(function(error) {
      console.error(error);
    });
    else{
      const GameScore = Parse.Object.extend("Comunidad");
      const gameScore = new GameScore();

      gameScore.set("userId", userObj.objectId);
      gameScore.set("comentario", this.state.comentario);
      gameScore.set("tag", this.state.tag);
      gameScore.save()
      .then((gameScore) => {
        alert('Mensaje guardado');
        window.location.reload();
      }, (error) => {
        console.log('Failed to create new object, with error code: ' + error.message);
      });
    }
  }
  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }

  componentWillReceiveProps(nextProps){
    let user = nextProps.user;
    this.setState({
      fullname: user.fullname,
      email: user.email,
      nit: user.nit,
      telefono: user.telefono,
      fechainicio: user.fechainicio,
      fechacorte: user.fechacorte,
      objetivo: user.objetivo,
      user: user
    })
  }

  async componentDidMount() {
    let myfat = [];
    let mycomments = [];

    if(this.props && this.props.user){
      let user = this.props.user;
      this.setState({
        fullname: user.fullname,
        email: user.email,
        nit: user.nit,
        telefono: user.telefono,
        fechainicio: user.fechainicio,
        fechacorte: user.fechacorte,
        objetivo: user.objetivo,
        user: user
      })
    }
    if(userObj && (userObj.get("conjunto") === "onlinegratis2" || userObj.get("conjunto") === "onlinegratis")){
      const GameScore = Parse.Object.extend("Comunidad");
      const query = new Parse.Query(GameScore);
      query.limit(500); //mycomunidad
      query.doesNotExist("padre");
      query.notEqualTo("objectId", "HoC0ZlK9Wv");
      const object = await query.find();

      const Respuestas = Parse.Object.extend("Comunidad");
      const queryR = new Parse.Query(Respuestas);
      queryR.limit(500); //mycomunidad
      queryR.exists("padre");
      queryR.notEqualTo("objectId", "HoC0ZlK9Wv");
      let respuestas = {}
      const objectR = await queryR.find();
      for (var i = 0; i < objectR.length; i++) {
        let respuestaObj = objectR[i];
        respuestas[respuestaObj.get("padre")] =  !respuestas[respuestaObj.get("padre")] ? [] : respuestas[respuestaObj.get("padre")]
        respuestas[respuestaObj.get("padre")].push(respuestaObj)
      }

      const Intro = Parse.Object.extend("Comunidad");
      const queryI = new Parse.Query(Intro);
      queryI.limit(500); //mycomunidad
      queryI.get("HoC0ZlK9Wv");
      const bienvenida = await queryI.find();

      const queryU = new Parse.Query(Parse.User);
      queryU.equalTo("conjunto", "onlinegratis");  // find all the women
      queryU.limit(500);
      const women = await queryU.find();

      const queryAdmin = new Parse.Query(Parse.User)
      queryAdmin.get("YDryQ4IroC");
      const admin = await queryAdmin.first();
      this.setState({mycomunidad: women, comunidad: object, admin, bienvenida, respuestas })
    }

    if(userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" && userObj.get("tipocliente")=="grupo"){
      getAsistenciasRanking().then(results => {
        let resultados = JSON.parse(results);
        resultados.results.sort(function (a, b) {
          return a.asistencias - b.asistencias;
        });
      
        this.setState({ranking: resultados.results.reverse(), loading: false}, () => {
          let positionR = this.state.ranking.findIndex(p => p.objectId === userObj.id)
          if(positionR !== -1)
            this.setState({myranking: positionR});
        });
      
      });
      if(userObj.get("fechainicio"))
        getUserAsistencias().then(results => {
          let result = JSON.parse(results);
          let asistencias = [];
          for (var i = 0; i < result.results.length; i++)
            asistencias.push(result.results[i]);

          this.setState({ ultimaAsistencia: asistencias[0] });
          this.setState({ asistencias });
        });

      getUserFat().then(results => {
        let result = JSON.parse(results);
        for (var i = 0; i < result.results.length; i++) {
          myfat.push(result.results[i]);
          // dashboardFatChart.labels.push(result.results[i].Fecha);
          // dashboardMuscleChart.labels.push(result.results[i].Fecha);
          // dashboardWeightChart.labels.push(result.results[i].Fecha);
          // dashboardFatChart.datasets[0].data.push(result.results[i].BodyFat);
          // dashboardWeightChart.datasets[0].data.push(result.results[i].Peso);
          // dashboardMuscleChart.datasets[0].data.push(
          //   (parseFloat(result.results[i].Peso) -
          //     (parseFloat(result.results[i].Peso) *
          //       parseFloat(result.results[i].BodyFat)) /
          //       100).toFixed(2)
          // );
        }
        myfat = myfat.reverse()
        this.setState({ myfat });
      });
    }


    getTips(null).then(tips => {
      let tipsAr = JSON.parse(tips);
      this.setState({ tips: tipsAr.results})
    })

    getUserComments().then(results => {
      let result = JSON.parse(results);
      for (var i = 0; i < result.results.length; i++) {
        mycomments.push(result.results[i]);
      }
      this.setState({ mycomments });
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" || target.type === "radio" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleFileChange(event) {
    const self = this;
    var fileInput = document.getElementById('fotoPerfil');
    var reader = new FileReader();
    reader.onload = function (e) {
        // document.getElementById('fotoPerfil').setAttribute('src', e.target.result);
        self.setState({
          addpic: e.target.result
        })
    }
    reader.readAsDataURL(fileInput.files[0]);
    return false;
  }

  render() {
    const userJson = userObj.toJSON()
    const {comunidad, mycomunidad, admin, bienvenida, respuestas} = this.state
    var ultimaAsistencia = this.state.ultimaAsistencia ?
    ( <span>{this.state.ultimaAsistencia.fecha}</span> ) :
    ( <span>Usted no tiene asistencias</span> );

    let fechacorte = this.state.user && this.state.user.fechacorte_real ? this.state.user.fechacorte_real.iso : "";
    
    
    const fechaSplited = fechacorte.split("T");
    const fechaArray = fechaSplited[0].split("-");
    fechacorte = fechaArray[2] + "/" + parseInt(fechaArray[1]) + "/" + fechaArray[0];
    let fechacorteMax = this.state.user
    ? new Date(fechaArray[0], fechaArray[1], fechaArray[2])
    : new Date();
    
    let avatar = MasculinoAvatar
    if(this.state.sexo === "Femenino")
      avatar = FemeninoAvatar

    if(this.state.user && this.state.user.avatar)
      avatar = this.state.user.avatar

    return (
      <div id="page-wrap">
        <header className="App-header">
          <img src={logoBlanco} className="App-logo" alt="logo" />
        </header>

        <div className="container emp-profile">
                  {userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" ? (
                  <div className="row">
                    <div className="col-md-4">
                        <div className="profile-img">
                            <img src={avatar} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head">
                          <h5>
                              {this.state.fullname}
                          </h5>
                          <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
                            Cerrar Sesión
                          </button>
                          <br/>
                          <h6 style={{cursor:'pointer'}} onClick={() => this.setState({modal: true})}>
                                {this.state.objetivo && this.state.objetivo.length ? this.state.objetivo : "No has ingresado tu objetivo general"}
                          </h6>

                            <div>
                            <p className="proile-text">NÚMERO DE ENTRENAMIENTOS ÚLTIMO CORTE : <span>{this.state.asistencias.length}</span></p>
                            <p className="proile-text">AGENDA TU PRÓXIMO ENTRENAMIENTO : <AgendarForm fechacorteMax={fechacorteMax}/></p>
                            </div>

                          {userJson.conjunto && userJson.conjunto.length ? (
                            <button className="btn btn-outline-success"  onClick={() => this.setState({modal2: true})} >Ingresa tu último entrenamiento</button>
                          ) : (
                            <button className="btn btn-outline-success"  onClick={() => this.setState({modal2: true})} >Califica tu último entrenamiento</button>
                          )}

                          <Modal open={this.state.modal2} onClose={() => this.setState({modal2: false})} center>
                          {userJson.conjunto && userJson.conjunto.length ? (
                            <div>
                              <h5>Ingrea tu último entrenamiento</h5>
                              <div className="form-group shadow-textarea">
                                <CalificarForm usuario={userJson} />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <h5>Califica tu último entrenamiento:<br/> {ultimaAsistencia}</h5>
                              <div className="form-group shadow-textarea">
                                <CalificarForm asistencia={this.state.ultimaAsistencia} />
                              </div>
                            </div>
                          )}

                          </Modal>
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item">
                                  <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Escritorio</a>
                              </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                  ) : (
                    <div className="col-md-12 text-center">
                      <h5 className="card-title">COMUNIDAD</h5>
                      <div className="row text-justify">
                      { bienvenida && bienvenida.map( comunidad => {
                        let getUsuario = admin;
                        return(
                              <div className="post">
                                  <div className="wrap-ut pull-left">
                                      <div className="posttext pull-left">
                                          <h5>{getUsuario.get("fullname")}</h5>
                                          {comunidad.get("comentario").split("\n").map(function(item) {
                                            return (
                                              <span>
                                                {item}
                                                <br/>
                                              </span>
                                            )
                                          })}
                                      </div>
                                      <div className="clearfix"></div>
                                  </div>
                                  {/*<div className="postinfo pull-left">
                                  <div className="comments">
                                      <div className="commentbg">
                                          560
                                          <div className="mark"></div>
                                      </div>

                                  </div>
                                  <div className="views"><i className="fa fa-eye"></i> 1,568</div>
                                  <div className="time"><i className="fa fa-clock-o"></i> 24 min</div>
                                  </div>*/}
                                  <div className="clearfix"></div>
                              </div>
                        )}
                      )}
                      </div>
                      <form action="#" method="post" className="row">
                          <div className="col-md-12">
                          <textarea onChange={this.handleInputChange.bind(this)} className="form-control z-depth-1" name="comentario" id="exampleFormControlTextarea6" rows="3" placeholder="¿Qué deseas publicar?...">
                          </textarea></div>
                          <div className="col-md-3 text-center form-check">
                            <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="radio" name="tag" value="quiensoy" id="defaultCheck1" />
                            <label className="form-check-label" for="defaultCheck1">
                              ¿QUIÉN SOY YO?
                            </label>
                          </div>
                          <div className="col-md-3 text-center form-check">
                            <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="radio" name="tag" value="objetivo" id="objetivo" />
                            <label className="form-check-label" for="objetivo">
                              OBJETIVO
                            </label>
                          </div>
                          <div className="col-md-3 text-center form-check">
                            <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="radio" name="tag" value="rutina" id="rutina" />
                            <label className="form-check-label" for="rutina">
                              RUTINA
                            </label>
                          </div>
                          <div className="col-md-3 text-center form-check">
                            <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="radio" name="tag" value="ancla" id="ancla" />
                            <label className="form-check-label" for="ancla">
                              ¿QUÉ ME MOTIVA?
                            </label>
                          </div>
                          <div className="col"><button onClick = { () => { document.getElementById("fotoPerfil").click() }} className="btn btn-success" type="button"><i className="fa fa-camera"></i> Subir Foto</button><input className="fotoFile" id="fotoPerfil" type="file" name="fotoPerfil" onChange={this.handleFileChange.bind(this)} /></div>
                          <div className="col"><button onClick = { this.publicar.bind(this) } className="btn btn-success" type="button"><i className="fa fa-plus"></i> Publicar</button></div>
                      </form>
                    </div>
                  ) }
                    
                </div>
                {userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" ? (
                <div className="container emp-profile">
                  <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">Seguimiento Antropométrico</h5>
                            <h6 className="card-subtitle mb-2 text-muted">Hoy estás más cerca del cambio</h6>
                            <table className="table">
                              <thead className=" text-primary">
                                <tr>
                                  <th>Fecha</th>
                                  <th>Peso</th>
                                  <th>Grasa</th>
                                  <th>Músculo</th>
                                  <th>Ver Todo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.myfat.map(myfat => (
                                  <tr key={myfat.objectId}>
                                    <td>{myfat.Fecha}</td>
                                    <td>{myfat.Peso}Kg</td>
                                    <td>{myfat.BodyFat}%</td>
                                    <td>{myfat.skeletal_muscle}%</td>
                                    <td>
                                      <button
                                        onClick={() => {
                                          this.verMedidas(myfat)
                                        }}
                                        className="btn btn-outline-success"
                                      >
                                        Ver
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Modal open={this.state.modal} onClose={() => this.setState({modal: false})} center>
                              <h5>{this.state.medidaTitle}</h5>
                              <div className="form-group shadow-textarea">
                              <p>
                                <strong>Peso: </strong>
                                {this.state.Peso}
                              </p>
                              <p>
                                <strong>Porcentaje de Grasa: </strong>
                                {this.state.BodyFat}
                              </p>
                              <p>
                                <strong>Porcentaje de Músculo: </strong>
                                {this.state.skeletal_muscle}
                              </p>
                              <p>
                                <strong>Grasa Viceral: </strong>
                                {this.state.viceral_fat}
                              </p>
                              <p>
                                <strong>BMR: </strong>
                                {this.state.basal_metabolic_rate}
                              </p>
                              <p>
                                <strong>BMI: </strong>
                                {this.state.bmi}
                              </p>
                              <p>
                                <strong>Masa Ósea: </strong>
                                {this.state.bone_mass}
                              </p>
                              <strong>Comentarios</strong>
                              {this.state.comentarios_deportologo}
                              <strong>Comentarios Nutricionales</strong>
                              {this.state.comentariosNutricion}
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal styles={this.state.modalstyle} open={this.state.modal3} onClose={() => this.setState({modal3: false})} center>
                      <h5 className="card-title">{this.state.recetaTitle}</h5>
                      <br/>
                      {this.state.recetaBody}
                    </Modal>
                  </div>
                  ) : null}
                  { userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" && userJson.tipocliente && userJson.tipocliente !== "conjunto" && userJson.tipocliente !== "online" ? (
                    <div className="container emp-profile">
                      <div className="row">    
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title">Asistencias del último corte</h5>
                              <h6 className="card-subtitle mb-2 text-muted">Hoy estás más cerca del cambio</h6>
                              <table className="table">
                                <thead className=" text-primary">
                                  <tr>
                                    <th>Parque</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.asistencias.map(asistencia => (
                                    <tr key={asistencia.objectId}>
                                      <td>{asistencia.grupoId}</td>
                                      <td>{asistencia.fecha}</td>
                                      <td>{asistencia.hora}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title"><img width="60" src={podioIcon} alt="Ranking" /><br/> Ranking</h5>
                              <h6 className="card-subtitle mb-2 text-muted">Según tus entrenamientos este mes</h6>
                              <table className="table">
                                <thead className=" text-primary">
                                  <tr>
                                    <th>Nombre</th>
                                    <th>Asistencias</th>
                                  </tr>
                                </thead>
                                  <tbody>
                                    {this.state.ranking.map((cliente, index) => {
                                      if((!cliente.fullname.includes("Fitpal") && !cliente.fullname.includes("SAVY")))
                                      return (
                                      <tr key={cliente.objectId}>
                                        <td>
                                          {index===0 ? (<img width="40" src={GoldIcon} alt="Medalla de oro" />):(null) }
                                          {index===1 ? (<img width="40" src={SilverIcon} alt="Medalla de oro" />):(null) }
                                          {index===2 ? (<img width="40" src={BronceIcon} alt="Medalla de oro" />):(null) }
                                          &nbsp;{cliente.fullname}</td>
                                        <td>{cliente.asistencias}</td>
                                      </tr>
                                    )})}
                                  </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
          { comunidad && comunidad.map( comunidad => {
            let getUsuario = mycomunidad.find(fruit => fruit.id === comunidad.get("userId"));
            if(comunidad.get("userId") === userObj.id)
              getUsuario = userObj;

            if(comunidad.get("userId") === admin.id)
              getUsuario = admin;

            let avatarPost = MasculinoAvatar
            if(getUsuario && getUsuario.get("avatar"))
              avatarPost = getUsuario.get("avatar")
            else {
              if(getUsuario && getUsuario.get("sexo") === "Femenino")
                avatarPost = FemeninoAvatar

            }
            return(
              <div className="container emp-profile">
                  <div className="post">
                      <div className="wrap-ut pull-left">
                          <div className="userinfo pull-left">
                              <div className="avatar">
                                  <img src={avatarPost} alt=""/>
                                  <div className="status green">&nbsp;</div>
                              </div>

                              <div className="icons">
                                  {/*<img src="images/icon1.jpg" alt=""/><img src="images/icon4.jpg" alt=""/>*/}
                              </div>
                          </div>
                          <div className="posttext pull-left">
                              <h5>{getUsuario.get("fullname")}</h5>
                              {comunidad.get("comentario").split("\n").map(function(item) {
                                return (
                                  <span>
                                    {item}
                                    <br/>
                                  </span>
                                )
                              })}
                              <div>
                                {comunidad.get("tag") ? (<span className="badge badge-primary">{comunidad.get("tag")}</span>) : null }
                              </div>
                              <a onClick={() => this.setState({opened: comunidad.id})} className="btn-comentar">
                                <i className="fa fa-comment"></i> Comentar
                              </a> &nbsp;
                              <a onClick={() => this.setState({commentsopened: comunidad.id})} className="btn-comentar">
                                <i className="fa fa-list"></i> Ver Comentarios {respuestas && respuestas[comunidad.id] ? (respuestas[comunidad.id].length) : null}
                              </a>
                              <form onSubmit={this.responder.bind(this)} className={this.state.opened && this.state.opened === comunidad.id ? 'fadeIn row':'fadeOut row'} action="#" method="post">
                                  <div className="col-md-12">&nbsp;</div>
                                  <div className="col-md-12">
                                  <textarea onChange={this.handleInputChange.bind(this)} className="form-control z-depth-1" name="comentario" id={"resp"+comunidad.id} rows="2" placeholder="Comentario...">
                                  </textarea></div>
                                  <input type="hidden" value={comunidad.get("objectId")} name="padre" />
                                  <div className="col"><button onClick = { this.responder.bind(this) } className="btn btn-success" type="button"><i className="fa fa-send"></i> Enviar</button></div>
                              </form>
                              <div className={this.state.commentsopened && this.state.commentsopened === comunidad.id ? 'fadeIn row':'fadeOut row'}>
                              {console.log(respuestas["z0lgjDicGS"])}
                              { respuestas && respuestas[comunidad.id] && respuestas[comunidad.id].map( respuesta => {
                                let getUsuario = mycomunidad.find(fruit => fruit.id === respuesta.get("userId"));
                                if(respuesta.get("userId") === userObj.id)
                                  getUsuario = userObj;

                                if(respuesta.get("userId") === admin.id)
                                  getUsuario = admin;

                                let avatarPost = MasculinoAvatar
                                if(getUsuario && getUsuario.get("avatar"))
                                  avatarPost = getUsuario.get("avatar")
                                else {
                                  if(getUsuario && getUsuario.get("sexo") === "Femenino")
                                    avatarPost = FemeninoAvatar
                                }
                                return(
                                <div className="col-md-12">
                                  <div className="post">
                                      <div className="wrap-ut pull-left">
                                          <div className="userinfo pull-left">
                                            <div className="avatar">
                                                <img src={avatarPost} alt=""/>
                                                <div className="status green">&nbsp;</div>
                                            </div>
                                          </div>
                                          <div className="posttext pull-left">
                                              <h5>{getUsuario.get("fullname")}</h5>
                                              {respuesta.get("comentario").split("\n").map(function(item) {
                                                return (
                                                  <span>
                                                    {item}
                                                    <br/>
                                                  </span>
                                                )
                                              })}
                                          </div>
                                          <div className="clearfix"></div>
                                      </div>
                                      <div className="clearfix"></div>
                                  </div>
                                  <hr/>
                                </div>)})}
                              </div>
                          </div>
                          <div className="clearfix"></div>
                      </div>
                      <div className="postinfo pull-left">
                          {/*<div className="comments">
                              <div className="commentbg">
                                  560
                                  <div className="mark"></div>
                              </div>

                          </div>
                          <div className="views"><i className="fa fa-eye"></i> 1,568</div>
                          <div className="time"><i className="fa fa-clock-o"></i> 24 min</div>*/}
                      </div>
                      <div className="clearfix"></div>
                  </div>
              </div>
            )}
          )}
          <div className="container emp-profile">
                  <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <br/>
                          <div className="col-md-12">
                            <h5 className="card-title">TIPS</h5>
                          </div>
                          <div style={{"padding":"0 60px","maxWidth":800,"margin":"0 auto", width: "100vw"}}>
                          {window.innerWidth <= 800 ? (
                            <ItemsCarousel
                              gutter={12}
                              activePosition={'center'}
                              chevronWidth={60}
                              numberOfCards={1}
                              slidesToScroll={1}
                              outsideChevron={true}
                              showSlither={false}
                              firstAndLastGutter={false}
                              activeItemIndex={this.state.activeItemIndex}
                              requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                              rightChevron={<button className="btn btn-outline-success">{'>'}</button>}
                              leftChevron={<button className="btn btn-outline-success">{'<'}</button>}
                            >
                              {this.state.tips &&  this.state.tips.map(tip =>
                                <div
                                  key={tip.objectId}
                                  style={{
                                    height: 350,
                                    background: "#EEE",
                                  }}
                                >

                                  <h5 className="text-center"><br/>
                                  {tip.categoria==="General" ? (<img src={yogaIcon} width="60" alt={tip.title} />):(null)}
                                  {tip.categoria==="Saludable" ? (<img src={saludableIcon} width="60" alt={tip.title} />):(null)}
                                  {tip.categoria==="Nutricion" ? (<img src={nutricionIcon} width="60" alt={tip.title} />):(null)}
                                  {tip.categoria==="Entrenamiento" ? (<img src={entrenamientoIcon} width="60" alt={tip.title} />):(null)}
                                  {tip.categoria==="Salud Mental" ? (<img src={menteIcon} width="60" alt={tip.title} />):(null)}
                                  </h5>
                                  <h5 className="text-center"><br/>{tip.title}</h5>
                                  <p style={{
                                    padding: 10,
                                    fontSize: 12,
                                  }} >
                                    {tip.resumen}
                                    <br/>
                                    {tip.cuerpo ? (<button onClick={() => {
                                      this.verTip(tip)
                                    }} className="btn btn-outline-success">Ver más</button>) : (null)}
                                  </p>
                                </div>
                              )}
                            </ItemsCarousel>
                          ):(
                            <ItemsCarousel
                              gutter={12}
                              activePosition={'center'}
                              chevronWidth={60}
                              numberOfCards={2}
                              slidesToScroll={2}
                              outsideChevron={true}
                              showSlither={false}
                              firstAndLastGutter={false}
                              activeItemIndex={this.state.activeItemIndex}
                              requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                              rightChevron={<button className="btn btn-outline-success">{'>'}</button>}
                              leftChevron={<button className="btn btn-outline-success">{'<'}</button>}
                            >
                              {this.state.tips &&  this.state.tips.map(tip =>
                                <div
                                  key={tip.objectId}
                                  style={{
                                    height: 350,
                                    background: "#EEE",
                                  }}
                                >

                                  <h5 className="text-center"><br/>
                                  {tip.categoria==="General" ? (<img src={yogaIcon} width="60" alt={tip.title} />):(null)}
                                  {tip.categoria==="Saludable" ? (<img src={saludableIcon} width="60" alt={tip.title} />):(null)}
                                  {tip.categoria==="Nutricion" ? (<img src={nutricionIcon} width="60" alt={tip.title} />):(null)}
                                  {tip.categoria==="Entrenamiento" ? (<img src={entrenamientoIcon} width="60" alt={tip.title} />):(null)}
                                  {tip.categoria==="Salud Mental" ? (<img src={menteIcon} width="60" alt={tip.title} />):(null)}
                                  </h5>
                                  <h5 className="text-center"><br/>{tip.title}</h5>
                                  <p style={{
                                    padding: 10,
                                    fontSize: 12,
                                  }} >
                                    {tip.resumen}
                                    <br/>
                                    {tip.cuerpo ? (<button onClick={() => {
                                      this.verTip(tip)
                                    }} className="btn btn-outline-success">Ver más</button>) : (null)}
                                  </p>
                                </div>
                              )}
                            </ItemsCarousel>
                          )}
                          </div>
                          <br/>
                        </div>
                      </div>

                  </div>
            </div>
        </div>
    );
  }
}
