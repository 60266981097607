import React from 'react';
// import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import Escritorio from "../Escritorio.js";
import Home from "../Home.js";
import Agenda from "../Agenda.js";
import Entrenamientos from "../Entrenamientos.js";
import Nutricion from "../Nutricion.js";
import Aliados from "../Aliados.js";
import Recetas from "../Recetas.js";
import Progresos from "../Progresos.js";
import Chat from "../Chat.js";
import { createBrowserHistory } from "history";
import Menu from "./Menu.js";
import Modal from 'react-responsive-modal';
import Parse from "parse";
import { getUserData } from "../actions/AgendaHelper"; //Para traer al usuario
import '../Login/styles.css';
import '../App.css';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import InputMask from 'react-input-mask';
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";
const hist = createBrowserHistory();
const userObj = Parse.User.current();
const modalstyle = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "center"
  }
};
const modalstyle2 = {
  modal: {
    'color' : "white",
    'max-width': "90%",
    'width': "90vw",
    'text-align': "center",
    'background': 'url(https://vescolombia.com.co/wp-content/uploads/2019/08/570542-PLGYNL-325.jpg) no-repeat',
    'background-size': 'cover'
  }
};

class Root extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  constructor(props) {
    super(props)

    this.state = {
      location: hist.location,
      modal: false,
      modal2: false,
      modal3: false,
      user:null,
      consentimiento: null,
      fecha_de_nacimiento: null,
      eps: null,
      prepagada: null,
      objetivo: null,
      contacto: null,
      alimentos_gusta: null,
      alimentos_no_gusta: null,
      checkedItems: [],
      otra: null,
      cualOperacion: null,
      cualAntecedente: null,
      responsable: null,
      documentoresponsable: null
    }
    this.handleClick = this.handleClick.bind(this)
    this.showPayment = this.showPayment.bind(this)
    this.showBanner = this.showBanner.bind(this)
    this.getValoraciones = this.getValoraciones.bind(this)
    this.subirValoracion = this.subirValoracion.bind(this)
    hist.listen(this.handleNavigation.bind(this))
  }
  showPayment = () => {
    this.setState({
      modal: true
    });
  }
  showBanner = () => {
    this.setState({
      modal3: true
    });
  }
  showValoracion = () => {
    this.setState({
      modal2: true
    });
  }
  async getValoraciones() {
    const query = new Parse.Query("HistoriaClinica");
    query.equalTo("usuarioId", userObj.id);
    const results = await query.find();

    if(!results.length)
      this.showValoracion()
  }
  componentDidMount() {
    getUserData().then(user => {
      this.setState({
        user: user
      }, () => {
        const today = new Date();
        const fechacorte = user.fechacorte_real ? new Date(user.fechacorte_real.iso) : null
        if(fechacorte)
          if (fechacorte < today) { //mostrar popup de pago
             this.showPayment()
          }

        if(user.tipocliente && user.tipocliente==="conjunto"){
          this.getValoraciones()
        }
        // if(user.conjunto && user.conjunto==="onlinegratis"){
        //   this.showBanner()
        // }


      });
    });
  }

  getUserData(){
    getUserData().then(user => {
      this.setState({
        user: user
      });
    });
  }

  handleNavigation(location) {
    this.setState({
      location: location,
    })
  }
  isLeftClickEvent(event) {
    return event.button === 0;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.value : target.value;
    const name = target.name;
    if (name==="checkedItems") {
      const checkedItems = this.state.checkedItems
      if(target.checked)
        checkedItems.push(value)
      else{
        let index = checkedItems.indexOf(value);
        if (index > -1) {
          checkedItems.splice(index, 1);
        }
      }

      this.setState({
        checkedItems: checkedItems
      });
    }else{
      this.setState({
        [name]: value
      });
    }
  }

  isModifiedEvent(event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
  }
  handleClick(toPath) {
    // alert(toPath);
     hist.push(toPath)
  }
  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }
  subirValoracion() {
    const self = this;
    //Valido los campos
    const {
      consentimiento,
      fecha_de_nacimiento,
      eps,
      prepagada,
      objetivo,
      contacto,
      responsable,
      documentoresponsable
    } = this.state
    let menordeEdad = false
    let obligatorios = '<p>Los siguientes campos son obligatorios: <ul>';
    if (consentimiento && fecha_de_nacimiento) {
      if(fecha_de_nacimiento && !fecha_de_nacimiento.includes("_")){
        const fechaArray = fecha_de_nacimiento.split("/")
        const birthday = new Date(fechaArray[2], fechaArray[1], fechaArray[0]) //new Date(1980, 6, 31);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        if(Math.abs(ageDate.getUTCFullYear() - 1970) < 18)
          menordeEdad = true

        if(menordeEdad && !responsable && !documentoresponsable){
          Alert.error('<h4>Error</h4><p>Los menores de edad deben colocar un responsable y su documento</p>', {
                position: 'top-left',
                effect: 'slide',
                onShow: function () {
                    console.log('aye!')
                },
                beep: false,
                timeout: 'none',
                offset: 100
            });
        }else{
          const GameScore = Parse.Object.extend("HistoriaClinica");
          const gameScore = new GameScore();
          gameScore.set("usuarioId", userObj.id);
          Object.entries(this.state).forEach(([key, value]) => {
              console.log(`${key}: ${value}`);
              if(value)
                gameScore.set(key, value);
          });
          gameScore.save()
          .then((gameScore) => {
            // Execute any logic that should take place after the object is saved.
            Alert.success('<h4>Valoración Guardada</h4>', {
                  position: 'top-left',
                  effect: 'slide',
                  onShow: function () {
                      console.log('aye!')
                  },
                  beep: false,
                  timeout: 'none',
                  offset: 100
              });
            self.setState({modal2: false})
            window.location.reload();
          }, (error) => {
            // Execute any logic that should take place if the save fails.
            // error is a Parse.Error with an error code and message.
            alert('Failed to create new object, with error code: ' + error.message);
          });
        }
      }else if(fecha_de_nacimiento.includes("_")){
        Alert.error('<h4>Error</h4><p>Debe colocar su fechsa de nacimiento</p>', {
              position: 'top-left',
              effect: 'slide',
              onShow: function () {
                  console.log('aye!')
              },
              beep: false,
              timeout: 'none',
              offset: 100
          });
      }
    }else{
      if(!consentimiento || !consentimiento.length)
        obligatorios += '<li>Debe aceptar el consentimiento</li>'
      if(!fecha_de_nacimiento || !fecha_de_nacimiento.length)
        obligatorios += '<li>Fecha de nacimiento</li>'

      Alert.error('<h4>Error</h4><small>'+obligatorios+'</small>', {
            position: 'top-left',
            effect: 'slide',
            onShow: function () {
                console.log('aye!')
            },
            beep: false,
            timeout: 'none',
            offset: 100
        });
    }

  }
  render() {
    let fecha_de_nacimiento = this.state.fecha_de_nacimiento
    let menordeEdad = false
    const {user} = this.state;
    if(fecha_de_nacimiento && !fecha_de_nacimiento.includes("_")){
      const fechaArray = fecha_de_nacimiento.split("/")
      const birthday = new Date(fechaArray[2], fechaArray[1], fechaArray[0]) //new Date(1980, 6, 31);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      if(Math.abs(ageDate.getUTCFullYear() - 1970) < 18)
        menordeEdad = true
    }


    const pathname = this.state.location.pathname
    let route = (<Escritorio search={this.state.location.search} />)
    if (pathname === '/') {
      route = (<Escritorio user={this.state.user} search={this.state.location.search} />)
    }
    else if (pathname === '/home') {
      route = (<Home getUserData={this.getUserData.bind(this)} user={this.state.user} search={this.state.location.search} />)
    }
    else if (pathname === '/dashboard') {
      route = (<Escritorio user={this.state.user}  search={this.state.location.search} />)
    }
    else if (pathname === '/agenda') {
      route = (<Agenda user={this.state.user} search={this.state.location.search} />)
    }
    else if (pathname === '/entrenamientos') {
      route = (<Entrenamientos user={this.state.user}  search={this.state.location.search} />)
    }
    else if (pathname === '/aliados') {
      route =(<Aliados getUserData={this.getUserData.bind(this)} user={this.state.user} search={this.state.location.search} />)
    }
    else if (pathname === '/nutricion') {
      route =(<Nutricion getUserData={this.getUserData.bind(this)} user={this.state.user} search={this.state.location.search} />)
    }
    else if (pathname === '/recetas') {
      route =(<Recetas user={this.state.user} search={this.state.location.search} />)
    }
    else if (pathname === '/progresos') {
      route =(<Progresos user={this.state.user}  search={this.state.location.search} />)
    }
    else if (pathname === '/chat') {
      route =(<Chat user={this.state.user} search={this.state.location.search} />)
    }

    return (
      <div id="App" className="App">
        <Menu handleClick={this.handleClick}/>
        {route}
        <Modal styles={modalstyle} open={this.state.modal} center>
          <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
            Cerrar Sesión
          </button>
          <h5 className="card-title">CONOCE NUESTROS PLANES</h5>
          <br/>
          <p>No dejes de cumplir tus metas. Escoge el plan que más se ajuste a ti.</p>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
              <div className="card-header">
                <h5 className="card-title">PLAN ONLINE PERSONALIZADO</h5>
              </div>
                <div className="card-body" style={{lineHeight: 1}}>
                  <small>
                  ✓ Diagnóstico inicial de necesidades. Uno de nuestros entrenadores se comunica por llamada telefónica para realizar un cuestionario y entender las necesidades del cliente.<br/>
                  ✓ Establecimiento de objetivos a corto (1° mes), mediano (2° mes) y largo plazo (3° mes).<br/>
                  ✓ Rutinas de entrenamiento personalizadas planeadas de acuerdo a las necesidades, gustos, disposición de tiempo y objetivos del cliente + plan nutricional online.<br/>
                  ✓ Acompañamiento de resultados a través del perfil del cliente.<br/>
                  ✓ Diagnóstico trimestral.<br/>
                  ✓ Logros de resultados garantizados.
                  </small>
                  <br/>
                  <br/>
                  <p><strong>$150.000</strong></p>
                  <a href="https://vescolombia.com.co/product/entrenamiento-personalizado-online/">
                    <img alt="pagar" src="https://www.payulatam.com/img-secure-2015/boton_pagar_mediano.png"/>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
              <div className="card-header">
                <h5 className="card-title">PLAN FUNCIONAL SEMIPERSONALIZADO</h5>
              </div>
                <div className="card-body" style={{lineHeight: 1}}>
                  <small>
                  ✓ Valoración física inicial y cada 3 meses<br/>
                  ✓ Objetivos a corto (1° mes), mediano (2° mes) y largo plazo (3° mes).<br/>
                  ✓ Plan nutricional online.<br/>
                  ✓ Sesiones de entrenamiento semipersonalizadas, divertidas, al aire libre en grupos de máximo 6 personas.<br/>
                  ✓ 4 puntos de entrenamiento: Macarena, Chapinero, Virrey y Santa Paula<br/>
                  ✓ Acompañamiento de resultados.<br/>
                  ✓ Logros de resultados garantizados.
                  </small>
                  <br/>
                  <br/>
                  <p><strong>$157.000 - $2.332.000 (Escoje tu tipo de plan)</strong></p>
                  <a href="https://vescolombia.com.co/product/entrenamiento-funcional-semi-personalizado/">
                    <img alt="pagar" src="https://www.payulatam.com/img-secure-2015/boton_pagar_mediano.png"/>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
              <div className="card-header">
                <h5 className="card-title">PLAN PERSONALIZADO A DOMICILIO</h5>
              </div>
              <div className="card-body" style={{lineHeight: 1}}>
                <small>
                ✓ Los entrenamientos serán realizados en los días y horarios fijos acordados con el cliente.<br/>
                ✓ De 5 a 8 días congelables dependiendo de tu plan y reglas estipuladas <a target="_blank" className="success" href="https://vescolombia.com.co/servicios/entrenamiento-funcional/entrenamiento-personalizado/">aquí</a><br/>
                ✓ Los entrenamientos de la semana de congelamiento deben ser reprogramados según los términos explicados <a target="_blank" className="success" href="https://vescolombia.com.co/servicios/entrenamiento-funcional/entrenamiento-personalizado/">aquí</a>.<br/>
                ✓ Sesiones online y/o por Skype en caso de viaje.<br/>
                ✓ Entrenamientos online los días que no puedas entrenar en el día y horario acordado.
                </small>
                <br/>
                <br/>
                <p><strong>$470.000 - $820.000</strong></p>
                <a href="https://vescolombia.com.co/product/entrenamiento-personalizado/">
                  <img alt="pagar" src="https://www.payulatam.com/img-secure-2015/boton_pagar_mediano.png"/>
                </a>
              </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal styles={modalstyle2} open={this.state.modal3} center>
          <p>&nbsp;</p>
          <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fvidaejerciciosalud%2Fvideos%2F477405509476136%2F&show_text=1&width=560" width="560" height="382" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
          <p>&nbsp;</p>
          <h1>VES ¿CÓMO ADELGAZO?</h1>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h5>Prepárate para revolucionar tu estilo de vida, el 1o LIVE inicial será el 1 de septiembre a las 8PM.</h5>
          <h5>Tu programa empieza el 1 de septiembre hasta el 15 de septiembre. Te estaremos recordando a través del correo que registraste.</h5>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Modal>
        <Modal styles={modalstyle} open={this.state.modal2} center>
          <h5 className="card-title">Debes realizar tu valoración inicial para continuar</h5>
          <br/>
          <p>Ayúdanos con la siguiente información para poder ayudarte de manera personalizada con tus planes.</p>
          <div className="row" style={{padding: 20}}>
            <div className="col-md-12">
              <form className="login-form" onSubmit={this.onSubmitRegistro}>
                <div className="form-group row">
                  <label for="fecha_de_nacimiento" className="proile-rating col-md-4 col-form-label">FECHA DE NACIMIENTO</label>
                  <div className="col-md-6">
                    <InputMask mask="99/99/9999" type="text" name="fecha_de_nacimiento" className="form-control" placeholder="dia/mes/año" id="fecha_de_nacimiento"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.fecha_de_nacimiento} />
                  </div>
                </div>
                { menordeEdad ? (
                  <div>
                    <div className="form-group row">
                      <label for="responsable" className="proile-rating col-md-4 col-form-label">NOMBRE DE TU RESPONSABLE</label>
                      <div className="col-md-6">
                        <input type="text" name="responsable" className="form-control" placeholder="El nombre de tu padre/madre/acudiente" id="responsable"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.responsable} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="documentoresponsable" className="proile-rating col-md-4 col-form-label">DOCUMENTO DE TU RESPONSABLE</label>
                      <div className="col-md-6">
                        <input type="text" name="documentoresponsable" className="form-control" placeholder="El número de documento cc/ce/pp de tu padre/madre/acudiente" id="documentoresponsable"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.documentoresponsable} />
                      </div>
                    </div>
                  </div>
                ) : null}
                { (user &&  user.conjunto!=="onlinegratis") ? (
                    <div>
                    <div className="form-group row">
                      <label for="eps" className="proile-rating col-md-4 col-form-label">EPS</label>
                      <div className="col-md-6">
                        <input type="text" name="eps" className="form-control" placeholder="El nombre de tu eps" id="eps"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.eps} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="prepagada" className="proile-rating col-md-4 col-form-label">PREPAGADA</label>
                      <div className="col-md-6">
                        <input type="text" name="prepagada" className="form-control" placeholder="El nombre de tu medicina prepagada" id="prepagada"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.prepagada} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="contacto" className="proile-rating col-md-4 col-form-label">CONTACTO DE EMERGENCIA</label>
                      <div className="col-md-6">
                        <input type="text" name="contacto" className="form-control" placeholder="Nombre y telefono de emergencia" id="contacto"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.contacto} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="objetivo" className="proile-rating col-md-4 col-form-label">OBJETIVO</label>
                      <div className="col-md-6">
                        <input type="text" name="objetivo" className="form-control" placeholder="Cual es tu objetivo con la actividad física" id="objetivo"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.objetivo} />
                      </div>
                    </div>
                    </div>
                ) : null }

                <div className="form-group row">
                  <p className="proile-rating col-md-12 col-form-label">SELECCIONA DE CUAL DE LAS SIGUIENTES ENFERMEDADES HAZ PADECIDO:</p>
                </div>
                <div className="form-group row">
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Hipertensión arterial" id="defaultCheck1" />
                    <label className="form-check-label" for="defaultCheck1">
                      Hipertensión arterial
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Preinfarto" id="defaultCheck2" />
                    <label className="form-check-label" for="defaultCheck2">
                      Preinfarto
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Infarto agudo del miocardio" id="defaultCheck3" />
                    <label className="form-check-label" for="defaultCheck3">
                      Infarto agudo del miocardio
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Trombosis" id="defaultCheck4" />
                    <label className="form-check-label" for="defaultCheck4">
                      Trombosis
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Embolias" id="defaultCheck5"/>
                    <label className="form-check-label" for="defaultCheck5">
                      Embolias
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Asma" id="defaultCheck6"/>
                    <label className="form-check-label" for="defaultCheck6">
                      Asma
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Bronquitis" id="defaultCheck7"/>
                    <label className="form-check-label" for="defaultCheck7">
                      Bronquitis
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Rinitis" id="defaultCheck8"/>
                    <label className="form-check-label" for="defaultCheck8">
                      Rinitis
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Neumonia" id="defaultCheck9"/>
                    <label className="form-check-label" for="defaultCheck9">
                      Neumonia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="EPOC" id="defaultCheck10"/>
                    <label className="form-check-label" for="defaultCheck10">
                      EPOC
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="EPID" id="defaultCheck11"/>
                    <label className="form-check-label" for="defaultCheck11">
                      EPID
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Fracturas" id="defaultCheck12"/>
                    <label className="form-check-label" for="defaultCheck12">
                      Fracturas
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Esguinces" id="defaultCheck13"/>
                    <label className="form-check-label" for="defaultCheck13">
                      Esguinces
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Luxaciones" id="defaultCheck14"/>
                    <label className="form-check-label" for="defaultCheck14">
                      Luxaciones
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Ruptura de ligamientos" id="defaultCheck15"/>
                    <label className="form-check-label" for="defaultCheck15">
                      Ruptura de ligamientos
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Osteoporosis" id="defaultCheck16"/>
                    <label className="form-check-label" for="defaultCheck16">
                      Osteoporosis
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Osteopenia" id="defaultCheck17"/>
                    <label className="form-check-label" for="defaultCheck17">
                      Osteopenia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Artrosis" id="defaultCheck18"/>
                    <label className="form-check-label" for="defaultCheck18">
                      Artrosis
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Artritis reumatoídea" id="defaultCheck19"/>
                    <label className="form-check-label" for="defaultCheck19">
                      Artritis reumatoídea
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Miastenia Gravis" id="defaultCheck20"/>
                    <label className="form-check-label" for="defaultCheck20">
                      Miastenia Gravis
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Fibrosis muscular" id="defaultCheck21"/>
                    <label className="form-check-label" for="defaultCheck21">
                      Fibrosis muscular
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Hipotiroidismo" id="defaultCheck22"/>
                    <label className="form-check-label" for="defaultCheck22">
                      Hipotiroidismo
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Hipertiroidismo" id="defaultCheck23"/>
                    <label className="form-check-label" for="defaultCheck23">
                      Hipertiroidismo
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Anorexia" id="defaultCheck24"/>
                    <label className="form-check-label" for="defaultCheck24">
                      Anorexia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Bulimia" id="defaultCheck25"/>
                    <label className="form-check-label" for="defaultCheck25">
                      Bulimia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Desnutrición" id="defaultCheck26"/>
                    <label className="form-check-label" for="defaultCheck26">
                      Desnutrición
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Obesidad" id="defaultCheck27"/>
                    <label className="form-check-label" for="defaultCheck27">
                      Obesidad
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Alcohol" id="defaultCheck28"/>
                    <label className="form-check-label" for="defaultCheck28">
                      Alcohol
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Cigarrillo" id="defaultCheck29"/>
                    <label className="form-check-label" for="defaultCheck29">
                      Cigarrillo
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Sustancias psicoactivas" id="defaultCheck30"/>
                    <label className="form-check-label" for="defaultCheck30">
                      Sustancias psicoactivas
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Intoxicaciones" id="defaultCheck31"/>
                    <label className="form-check-label" for="defaultCheck31">
                      Intoxicaciones
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Envenenamiento" id="defaultCheck32"/>
                    <label className="form-check-label" for="defaultCheck32">
                      Envenenamiento
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Guillan Barré" id="defaultCheck33"/>
                    <label className="form-check-label" for="defaultCheck33">
                      Guillan Barré
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Esclerosis múltiple" id="defaultCheck34"/>
                    <label className="form-check-label" for="defaultCheck34">
                      Esclerosis múltiple
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Enfermedad de Alzheimer" id="defaultCheck35"/>
                    <label className="form-check-label" for="defaultCheck35">
                      Enfermedad de Alzheimer
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Enfermedad de Parkinson" id="defaultCheck36"/>
                    <label className="form-check-label" for="defaultCheck36">
                      Enfermedad de Parkinson
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Lesión medula espinal" id="defaultCheck37"/>
                    <label className="form-check-label" for="defaultCheck37">
                      Lesión medula espinal
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Lesión de plejo" id="defaultCheck38"/>
                    <label className="form-check-label" for="defaultCheck38">
                      Lesión de plejo
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Lesión nervio periférico" id="defaultCheck39"/>
                    <label className="form-check-label" for="defaultCheck39">
                      Lesión nervio periférico
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Hernia discal" id="defaultCheck40"/>
                    <label className="form-check-label" for="defaultCheck40">
                      Hernia discal
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Parálisis facial" id="defaultCheck41"/>
                    <label className="form-check-label" for="defaultCheck41">
                      Parálisis facial
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Síndrome del túnel carpiano" id="defaultCheck42"/>
                    <label className="form-check-label" for="defaultCheck42">
                      Síndrome del túnel carpiano
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Hemiplejia" id="defaultCheck43"/>
                    <label className="form-check-label" for="defaultCheck43">
                      Hemiplejia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Hemiparesia" id="defaultCheck44"/>
                    <label className="form-check-label" for="defaultCheck44">
                      Hemiparesia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Parálisis cerebral" id="defaultCheck45"/>
                    <label className="form-check-label" for="defaultCheck45">
                      Parálisis cerebral
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Distonía" id="defaultCheck46"/>
                    <label className="form-check-label" for="defaultCheck46">
                      Distonía
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Diplejia" id="defaultCheck47"/>
                    <label className="form-check-label" for="defaultCheck47">
                      Diplejia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Ataxia" id="defaultCheck48"/>
                    <label className="form-check-label" for="defaultCheck48">
                      Ataxia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Apraxia" id="defaultCheck49"/>
                    <label className="form-check-label" for="defaultCheck49">
                      Apraxia
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Atetosis" id="defaultCheck50"/>
                    <label className="form-check-label" for="defaultCheck50">
                      Atetosis
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="checkedItems" value="Cabeza y cuello" id="defaultCheck51"/>
                    <label className="form-check-label" for="defaultCheck51">
                      Operaciones en Cabeza y/o cuello
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <label for="objetivo" className="proile-rating col-md-4 col-form-label">ALGUNA OTRA?</label>
                  <div className="col-md-6">
                    <input type="text" name="otra" className="form-control" placeholder="Mensiona si haz padecido de alguna otra enfermedad" id="otra"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.otra} />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="objetivo" className="proile-rating col-md-4 col-form-label">PROCEDIMIENTOS QUIRÚRJICOS</label>
                  <div className="col-md-6">
                    <input type="text" name="cualOperacion" className="form-control" placeholder="Mensiona si haz tenido procedimientos quirúrjicos" id="cualOperacion"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.cualOperacion} />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="objetivo" className="proile-rating col-md-4 col-form-label">ANTECEDENTES FAMILIARES</label>
                  <div className="col-md-6">
                    <input type="text" name="cualAntecedente" className="form-control" placeholder="Enfermedades de tus familiares" id="cualAntecedente"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.cualAntecedente} />
                  </div>
                </div>
                <div className="form-group row">
                <div style={{height: 200, overflow: 'auto', border: 'solid', borderWidth: 1, textAlign: 'justify'}} >
                  Yo { menordeEdad ? this.state.responsable : userObj.get("fullname") } { menordeEdad ? "acudiente de " + userObj.get("fullname") : "mayor de edad" }, identificado con CC/CE/PP número { menordeEdad ? this.state.documentoresponsable : userObj.get("nit") }, certifico que:
                  <p>1. He sido cuestionado con las preguntas anteriores, sobre mis antecedentes médicos, familiares y personales que pueden ocasionar riesgo para mi salud.</p>
                  <p>2. Que toda la información contenida en la “Valoración Inicial” es verdadera, y que tengo pleno conocimiento de que mi programa de entrenamiento y actividad física será formulado de acuerdo a dicha información. en tal sentido, exonero a VES de cualquier daño a mi salud que pueda derivarse por haber suministrado información falsa, imprecisa o insuficiente.</p>
                  <p>3. Que toda la información que he suministrado ha sido veraz y no queda ninguna adicional por revelar, por lo cual me hago responsable por cualquier hecho que ocurra, en caso de no respetar las indicaciones dadas en el programa de entrenamiento y actividad física sugerido.</p>
                  <p>4. Que cualquier actividad adicional realizada fuera de mi programa de entrenamiento y actividad física es de mi entera responsabilidad, por lo que exonero a VES de cualquier daño que pueda generarse por dichas actividades extras.</p>
                  <p>5. Que VES me remienda la realización un chequeo médico periódico con un médico deportologo con el fín de identificar posibles factores de riesgo osteomuscular y cardiovascular adicionales a los informados en esta “Valoración Inicial”.</p>
                  <p>6. Que es mi responsabilidad realizar exámenes médicos de seguimiento, por lo que me comprometo a reportar a VES, cualquier cambio en mi estado de salud que pueda influir en mi práctica de entrenamiento.</p>
                  <p>7. Que he leído y entiendo en su integridad este consentimiento informado y especialmente el presente documento de exoneración de responsabilidad y asunción expresa de riesgo, entendiendo a cabalidad sus efectos y alcance, entiendo que he renunciado a derechos legales al firmarlo y lo firmo de forma libre y voluntaria.</p>
                </div>
                  <div className="form-check form-check-inline">
                    <input onChange={this.handleInputChange.bind(this)} className="form-check-input" type="checkbox" name="consentimiento" value="consentimiento" id="defaultCheck52"/>
                    <label className="form-check-label" for="defaultCheck52">
                      He leído y acepto los términos del consentimiento informado.
                    </label>
                  </div>
                </div>
                <div className="form-check">
                {!this.state.consentimiento ? (
                  <a onClick={()=>{
                    Alert.error('<h4>Error</h4><p>"Debes leer y guardar el consentimiento informado"</p>', {
                        position: 'top-left',
                        effect: 'slide',
                        onShow: function () {
                            console.log('aye!')
                        },
                        beep: false,
                        timeout: 'none',
                        offset: 100
                    }); }} className="btn btn-success float-right">Guardar</a>
                ) : (
                  <a onClick={()=>{ this.subirValoracion()}} className="btn btn-success float-right">Guardar</a>
                )}

                </div>

              </form>
            </div>
          </div>
          <Alert stack={{limit: 3}} html={true} />
        </Modal>
      </div>
    )
  }
}

export default Root;
