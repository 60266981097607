import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getParques, getHorarios, agendar } from "../../actions/AgendaHelper";

export default class AgendarForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      startDate: new Date(),
      calificacion: null,
      comentario: null,
      parques: [],
      parque: null,
      horarios: [],
      clase: null,
      horarioLabel: "Seleccionar...",
      parqueName: null
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(date) {
    this.setState({
      startDate: date
    });
  }
  handleOptionChange(changeEvent) {
  this.setState({
    calificacion: changeEvent.target.value
  });
} componentDidMount() {
    getParques().then(results => {
      let result = JSON.parse(results);
      this.setState({ parques: result });
    });
  }
  handleinputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  handleHorarioChange(event){
    const target = event.target;
    const value = target.value;
    this.setState({
      clase: value
    });
  }
  handleParqueChange(event){
    this.setState({ horarioLabel: "Cargando horarios del parque" });
    const target = event.target;
    const value = target.value;
    //Get parque name
    const { parques } = this.state;

    for (var i = 0; i < parques.length; i++) {
      let parque = parques[i];
      console.log(parque.key);
      if (parque.key === parseInt(value)) {
        getHorarios(value, this.state.startDate.getDay()).then(results => {
          let result = JSON.parse(results);
          this.setState({
            horarios: result,
            parqueName: parque.label,
            parque: value,
            horarioLabel: "Seleccione horario"
          });
        });
      }
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    
    const { horarios } = this.state;
    const { startDate } = this.state;
    const { clase } = this.state;
    const { parqueName } = this.state;
    const { parque } = this.state;
    if (startDate && horarios) {
      //Parse start date to a dd/mm/yyyy
      var mm = this.state.startDate.getMonth() + 1; // getMonth() is zero-based
      mm = ("0" + mm).slice(-2);
      var dd = this.state.startDate.getDate();
      let fakedate = dd + "/" + mm + "/" + this.state.startDate.getFullYear();
      let startTimeArr = clase.split(" - ");
      let startTime = startTimeArr[0].split(":");
      startDate.setHours(startTime[0], startTime[1]);
      // alert(startDate);
      let now = new Date();
      // alert(now);
      let hours = Math.abs(startDate - now) / 36e5;
      // alert(hours);
      /**
       * Reviso también en agendar (Helper) los cupos ese día en ese parque en ese horario
       */

      if(hours>2){
        agendar(fakedate, clase, parqueName, parque).then(
          results => {
            let resultado = JSON.parse(results);
            alert(resultado.result);
  
          }
        );
      }else{
        alert("NO PUEDES AGENDAR CON MENOS DE 2 HORAS DE ANTICIPACIÓN")
      }
      
    } else {
      alert("Debe llenar todos los campos");
    }
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
          <p className="proile-text">1. Selecciona el día</p>
          <div className="form-group">
            <DatePicker
              className="form-control"
              selected={this.state.startDate}
              onChange={this.handleChange}
              minDate={new Date()}
              maxDate={this.props.fechacorteMax}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <p className="proile-text">2. Selecciona el parque</p>
          <div className="form-group">
            <select className="form-control" onChange={this.handleParqueChange.bind(this)}  type="select" name="parque" id="exampleSelect">
              <option>Seleccionar...</option>
              {this.state.parques.map(parque => (
                <option key={parque.key} value={parque.key}>{parque.label}</option>
              ))}
            </select>
          </div>

          <p className="proile-text">3. Selecciona la hora</p>
          <div className="form-group">
            <select className="form-control" onChange={this.handleHorarioChange.bind(this)} type="select" name="clase" id="exampleSelect">
              <option>{this.state.horarioLabel}</option>
              {this.state.horarios.map(horario => (
                <option key={horario.horario} value={horario.horario}>{horario.horario}</option>
              ))}
            </select>
          </div>
        <button type="button" className="btn btn-outline-success" onClick={this.handleSubmit.bind(this)} >Agendar</button>
      </form>
    );
  }
}
