/**
 * Functions General Helper.js
 *
 * - a simple Helper wrapper, do all we need to fetch api
 * - and get user datas
 */
import Parse from "parse";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";

export function getAliados() {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Alianzas', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}
export function getUserFat() {
  let userObj = Parse.User.current();
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Medidas?limit=1000&order=createdAt&where={"UsuairioId":"'+userObj.id+'"}', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getTips(cat) {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();

    if(cat)
      xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Tips?where={"ubicacion":{"$all":["'+cat+'"]}}', true);
    else
      xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Tips', true);

    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status===200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getUserData() {
  return new Promise((resolve, reject) => {

    return Parse.User.current().fetch().then((userObj) => {
      // The object was refreshed successfully.
      resolve(userObj.toJSON());
    }, (error) => {
      // The object was not refreshed successfully.
      // error is a Parse.Error with an error code and message.
       let userObj = Parse.User.current();
       resolve(userObj._toFullJSON());
    })
  })
  // console.log(userObj);
  // return userObj.result._toFullJSON();
  // userObj.fetch().then((userObj) => {
  //   // The object was refreshed successfully.
  //   return userObj._toFullJSON();
  // }, (error) => {
  //   // The object was not refreshed successfully.
  //   // error is a Parse.Error with an error code and message.
  //   userObj = Parse.User.current();
  //   return userObj._toFullJSON();
  // });

}

export function getParques() {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://vescolombia.com.co/json/?accion=parques', true);
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getProgresos() {
  let userObj = Parse.User.current();
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Progresos?limit=1000&order=-fecha&where={"userId":"'+userObj.id+'"}', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getChat(subject) {
  let userObj = Parse.User.current();
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Chat?limit=1000&order=createdAt&where={"userId":"'+userObj.id+'", "subject":"'+subject+'"}', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getRecetas() {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Recetas', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getHorarios(parque, dia, parqueName) {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    console.log('https://vescolombia.com.co/json/?accion=horario&parque='+parque+'&dia='+dia);
    xhttp.open("GET", 'https://vescolombia.com.co/json/?accion=horario&parque='+parque+'&dia='+dia, true);
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        console.log(xhttp.response);
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function agendar(fecha, clase, parqueName, parque) {
  let userObj = Parse.User.current();
  var porcionces = clase.split(" - ");//$porciones = explode("|-|", $_POST['clase']); 
  let cupos = 0;
  var xhttp0 = new XMLHttpRequest();
  xhttp0.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Booking?where={"fechafake":"'+fecha+'", "clase":"'+parqueName+' '+clase+'", "horaclase":"'+porcionces[0]+'"}', false);
  xhttp0.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
  xhttp0.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
  xhttp0.send();
  if (xhttp0.status === 200) {
    var response = JSON.parse(xhttp0.responseText);
    cupos = response.results.length;
    console.log(cupos);
    if(cupos > 5){
      alert("Los cupos se encuentran llenos");
    }
  }
  return new Promise((resolve, reject) => {
    if(cupos > 5){
      resolve('{"result": "Los cupos se encuentran llenos"}');
    }else{
      var xhttp = new XMLHttpRequest();
    xhttp.open("POST", "https://miembros.vescolombia.com.co/parse/functions/agendar", true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
    
      if (xhttp.status === 200) {
        console.log(xhttp.response);
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send('{"userId":"'+userObj.id+'", "fecha":"'+fecha+'", "clase":"'+clase+'", "parqueName":"'+parqueName+'"}');
    }
    
  });
}

export function cancelarBooking(asistencia, fecha, hora) {
  let fechaarray = fecha.split("/");
  var fechareal = new Date(fechaarray[1]+"/"+fechaarray[0]+"/"+fechaarray[2]+" "+hora);
  var date = new Date();
  var hours = Math.abs(date - fechareal) / 36e5;
  if (hours < 4) {
    return "No puedes cancelar con menos de 4 horas de anticipación";
  }else{
    var xhttp = new XMLHttpRequest();
    xhttp.open("DELETE", "https://miembros.vescolombia.com.co/parse/classes/Booking/"+asistencia, false);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.send();
    return "Tu reserva fué cancelada";
  }
}

export function getAlimentos() {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Alimentos?limit=2000', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}

export function getUserBooking() {
  let userObj = Parse.User.current();
  var date = new Date();
  var month = String(date.getMonth() + 1);
  month = ("0" + month).slice(-2);
  var day = String(date.getDate());
  var year = String(date.getFullYear());
  var firstDayString = String(year) + '-' + String(month) + '-' + String(day) + 'T00:00:00.249Z';
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", 'https://miembros.vescolombia.com.co/parse/classes/Booking?limit=1000&order=-createdAt&where={"userId":"'+userObj.id+'", "fecha":{"$gte":{"__type":"Date","iso":"'+firstDayString+'"}}}', true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.onload = function() {
      if (xhttp.status === 200) {
        resolve(xhttp.response);
      } else {
        reject(Error(xhttp.statusText));
      }
    };
    xhttp.send();
  });
}
