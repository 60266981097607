import React from 'react';
// import store from 'store';
import './styles.css';
import '../App.css';
import logoBackground from "../Images/backgroundlogin.jpg";
import logoBlanco from "../Images/logoBlancox3.png";
import Modal from 'react-responsive-modal';
import Parse from "parse";
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";
const modalstyle = {
  modal: {
   'background': "#FFF",
    'max-width': "500px",
    'width': "100vw",
  }
};


class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      conjunto: '',
      email: '',
      telefono: '',
      direccion: '',
      documento: '',
      error: false,
      modal: false,
      fullname: '',
      modalResetPsw: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitRegistro = this.onSubmitRegistro.bind(this);
    this.ressetPassword = this.ressetPassword.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async ressetPassword(e) {
    const { username, password, conjunto, telefono, direccion, sexo, email, documento, fullname } = this.state;
    if(!email){
      Alert.error('<h4>Error</h4><small>Debes ingresar un correo electrónico</small>', {
        position: 'top-left',
        effect: 'slide',
        onShow: function () {
            console.log('aye!')
        },
        beep: false,
        timeout: 'none',
        offset: 100
      });
    }else{
      Parse.User.requestPasswordReset(email)
        .then(() => {
        // Password reset request was sent successfully
          Alert.success('<h4>Casi listo</h4><small>Revisa tu correo y renueva tu contraseña</small>', {
            position: 'top-left',
            effect: 'slide',
            onShow: function () {
                console.log('aye!')
            },
            beep: false,
            timeout: 'none',
            offset: 100
          });
        }).catch((error) => {
        // Show the error message somewhere
        //alert("Error: " + error.code + " " + error.message);
          Alert.error('<h4>Error</h4><small>'+ error.message+'</small>', {
            position: 'top-left',
            effect: 'slide',
            onShow: function () {
                console.log('aye!')
            },
            beep: false,
            timeout: 'none',
            offset: 100
          });
        });
    }
  }
  async onSubmitRegistro(e) {
    const { username, password, conjunto, telefono, direccion, sexo, email, documento, fullname } = this.state;
    if (username.length && password.length && email.length && conjunto.length) {
      var customUser = new Parse.User();
      customUser.set("username", username);
      customUser.set("fullname", fullname);
      customUser.set("password", password);
      customUser.set("email", email);
      if(conjunto == "entreverde" || conjunto == "santacatalina")
        customUser.set("conjunto", conjunto);
      customUser.set("direccion", direccion);
      customUser.set("sexo", sexo);
      customUser.set("telefono", telefono);
      customUser.set("nit", documento);
      if(conjunto == "entreverde" || conjunto == "santacatalina")
        customUser.set("tipocliente", "conjunto");
      else
        customUser.set("tipocliente", conjunto);

      customUser.set("isclient", "SI");
      customUser.signUp().then((user) => {
        // user is an instance of CustomUser
        // user.doSomething(); // return 5
        // user.get('foo');    // return 'bar'
        window.location.reload()
      }, (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message. 
        if(error.message == "Account already exists for this username."){
          Alert.error('<h4>Error</h4><small>Ya existe una cuenta con ese usuario. Prueba con otro</small>', {
            position: 'top-left',
            effect: 'slide',
            onShow: function () {
                console.log('aye!')
            },
            beep: false,
            timeout: 'none',
            offset: 100
          });
        } else if(error.message == "Account already exists for this email address."){
          Alert.error('<h4>Error</h4><small>Ya existe una cuenta con ese correo electrónico.</small>', {
            position: 'top-left',
            effect: 'slide',
            onShow: function () {
                console.log('aye!')
            },
            beep: false,
            timeout: 'none',
            offset: 100
          });
        } else
          alert('Error: ' + error.message);
      });
      
    }else{
      e.preventDefault();
      let obligatorios = '<p>Los siguientes campos son obligatorios: <ul>';
      if(!username.length)
        obligatorios += '<li>Usuario</li>'
      if(!password.length)
        obligatorios += '<li>Clave</li>'
      if(!email.length)
        obligatorios += '<li>Correo electrónico</li>'
      if(!conjunto.length)
        obligatorios += '<li>Conjunto Residencial</li>'
      obligatorios += '</ul></p>'
      Alert.error('<h4>Error</h4><small>'+obligatorios+'</small>', {
            position: 'top-left',
            effect: 'slide',
            onShow: function () {
                console.log('aye!')
            },
            beep: false,
            timeout: 'none',
            offset: 100
        });
    }
  }
  onSubmit(e) {

    e.preventDefault();

    const { username, password } = this.state;

    if (username && password) {
        Parse.User.logIn(username, password).then(user => {
              window.location.reload();
          })
          .catch(error => {
            const { message } = error;
            alert(message);
          });
      } else {
        return this.setState({ error: true });
      }

    console.log("you're logged in. yay!");
    // store.set('loggedIn', true);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    return (
      <section className="login-block">
        <div className="container">
	        <div className="row">
		        <div className="col-md-4 login-sec">
		          <img className="d-block img-fluid" src={logoBlanco} alt="First slide" />
              <form className="login-form" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label for="exampleInputEmail1" className="text-uppercase">USUARIO</label>
                  <input type="text" name="username" className="form-control" placeholder="" id="usuario"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.usuario} />
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1" className="text-uppercase">CLAVE</label>
                  <input type="password" name="password" className="form-control"
                            id="password"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.password} placeholder=""/>
                </div>
                <div className="form-check">
                  <a onClick={() => this.setState({modal: true})} className="btn btn-warning float-right">Regístrate</a>
                  <button type="submit" className="btn btn-success float-right">Ingresa</button>
                </div>
                <a style={{color:'red'}} onClick={() => this.setState({modalResetPsw: true})} href="#">¿Olvidaste tu contraseña?</a>
                <Modal styles={modalstyle} open={this.state.modalResetPsw} onClose={() => this.setState({modalResetPsw: false})} center>
                  <p className="proile-rating">¿OLVIDASTE TU CONTRASEÑA?</p>
                  <p>Ingresa el correo electrónico con el cual te registraste y si que los pasos para cambiar tu contraseña. Si no recuerdas el correo con el cuál te registraste, ponte en contacto con nosotros.</p>
                  <div className="form-group shadow-textarea">
                    <form className="login-form">
                      <div className="form-group">
                        <label for="email" className="proile-rating">CORREO ELECTÓNICO</label>
                        <input type="text" name="email" className="form-control"
                                  id="email"
                                  onChange={this.handleInputChange.bind(this)}
                                  value={this.state.email} placeholder=""/>
                      </div>
                      <a onClick={this.ressetPassword} style={{color:'white'}} className="btn btn-success float-right">Enviar</a>
                    </form>
                  </div>
                  <Alert stack={{limit: 3}} html={true} />
                </Modal>
                <Modal styles={modalstyle} open={this.state.modal} onClose={() => this.setState({modal: false})} center>
                  <p className="proile-rating">REGÍSTRATE EN VES:</p>
                  <div className="form-group shadow-textarea">
                  <form className="login-form">
                    <div className="form-group">
                      <label for="username" className="proile-rating">USUARIO</label>
                      <input type="text" name="username" className="form-control" placeholder="" id="usuario"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.usuario} />
                    </div>
                    <div className="form-group">
                      <label for="password" className="proile-rating">CLAVE</label>
                      <input type="password" name="password" className="form-control"
                                id="password"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.password} placeholder=""/>
                    </div>
                    <div className="form-group">
                      <label for="conjunto" className="proile-rating">ESCOGE TU PLAN</label>
                      <select name="conjunto" className="form-control"
                                id="conjunto"
                                onChange={this.handleInputChange.bind(this)}
                      >
                        <option>Selecciona tu plan</option>
                        <option value="entreverde">Conjunto Entreverde</option>
                        <option  value="santacatalina">Conjunto Santa Catalina</option>
                        <option  value="online">Online</option>
                        <option  value="grupo">Parque</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="fullname" className="proile-rating">NOMBRE COMPLETO</label>
                      <input type="text" name="fullname" className="form-control"
                                id="fullname"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.fullname} placeholder=""/>
                    </div>
                    <div className="form-group">
                      <label for="email" className="proile-rating">CORREO ELECTÓNICO</label>
                      <input type="text" name="email" className="form-control"
                                id="email"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.email} placeholder=""/>
                    </div>
                    <div className="form-group">
                      <label for="documento" className="proile-rating">DOCUMENTO DE IDENTIDAD</label>
                      <input type="text" name="documento" className="form-control"
                                id="documento"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.documento} placeholder=""/>
                    </div>
                    <div className="form-group">
                      <label for="" className="proile-rating">SEXO</label>
                      <select onChange={this.handleInputChange.bind(this)} name="sexo" type="text" className="form-control">
                        <option value="Por favor ingresa tu sexo">Selecciona</option>
                        <option value="Femenino">Femenino</option>
                        <option value="Masculino">Masculino</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="telefono" className="proile-rating">TELÉFONO</label>
                      <input type="text" name="telefono" className="form-control"
                                id="telefono"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.telefono} placeholder=""/>
                    </div>
                    <div className="form-group">
                      <label for="direccion" className="proile-rating">DIRECCIÓN</label>
                      <input type="text" name="direccion" className="form-control"
                                id="direccion"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.direccion} placeholder=""/>
                    </div>
                    <div className="form-check">
                      <a onClick={this.onSubmitRegistro} className="btn btn-success float-right">Registrarme</a>
                    </div>
                  </form>
                  </div>
                  <Alert stack={{limit: 3}} html={true} />
                </Modal>

              </form>
		        </div>
        		<div className="col-md-8" style={{backgroundImage: `url(${ logoBackground })`,
       backgroundRepeat  : 'no-repeat',
       backgroundSize    : 'cover',
       backgroundPosition: 'top',}}>
              <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <div className="carousel-caption d-none d-md-block">
                      <div className="banner-text">
                          <h2>Bienvenido</h2>
                          <p>Ingresa y descubre el placer de entrenar. Entrena, aliméntate de manera saludable y escríbenos tus comentarios</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
		      </div>
	      </div>
      </div>
    </section>
    );
  }
}

export default Login;
