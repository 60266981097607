import React from 'react';
import './App.css';
import { createBrowserHistory } from "history";
// import { Router, Route, Switch } from "react-router-dom";
import Login from './Login';
import Root from './routes/Router.js';
//scp -r build/* root@45.55.41.53:/var/parse-server-example/public/
import Parse from "parse";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";

const hist = createBrowserHistory();

function handleNavigation(location) {
  console.log(location.pathname)
}

// Handle the initial location
handleNavigation(hist.location)

// Handle subsequent navigation events
hist.listen(handleNavigation)


function App() {
  if (!Parse.User.current())
    return (
      <Login />
    );

  return (
      <Root />
  );
}

export default App;
