import React from 'react';
import logoBlanco from "./Images/logoBlancox3.png";
import MasculinoAvatar from './Images/Masculino.svg';
import FemeninoAvatar from './Images/Femenino.svg';
import './App.css';
import trainIcon from "./Images/weightlifter.svg"
import CalificarForm from "./components/Forms/CalificarForm";
import ReactHtmlParser from 'react-html-parser';
import { getMisRutinas, getEjercicios, getMisRutinasOnline } from "./actions/RutinasHelper";
import { getTips } from "./actions/AgendaHelper"
import Modal from 'react-responsive-modal';
import ItemsCarousel from 'react-items-carousel';
import Parse from "parse";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";
const userObj = Parse.User.current();
const modalstyle = {
  modal: {
   'background': "#b2dbbf",
    'max-width': "500px",
    'width': "100vw",
  }
};

const modalstyle2 = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "justify",
    'padding': 20
  }
};
export default class Entrenamientos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      asistencias: [],
      myranking: 0,
      loading: true,
      modal: false,
      modal2: false,
      modal3: false,
      iframe: null,
      rutinas:[],
      rutinaCircuito: null,
      rutinaTitle: null,
      rutinaBody: null,
      rutina: null,
      porcentaje: null,
      ejercicios: [],
      modalstyle: null,
      tips: [],
      activeItemIndex: 0,
      modal4: false
    };
    this.rutinaDone = this.rutinaDone.bind(this);
    this.verRutina = this.verRutina.bind(this);
    this.getCircuito = this.getCircuito.bind(this);
    this.verTip = this.verTip.bind(this)
  }
  verTip(tip){
    this.setState({
      modal4: true,
      recetaTitle: tip.title,
      modalstyle: modalstyle2,
      recetaBody: ReactHtmlParser(tip.cuerpo.replace(/\\"/g, '"'))
    });
  }

  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        // window.location.reload();
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }
  verRutina(rutina) {
    if (rutina.Rutina) {
      this.setState({
        rutinaCircuito: null,
        rutinaTitle: rutina.Titulo,
        rutinaBody: ReactHtmlParser(rutina.Rutina.replace(/\\"/g, '"'))
      }, ()=>{this.setState({modal:true})} );
    } else {
      this.setState({
        rutinaBody: null,
        rutinaTitle: rutina.Titulo,
        rutinaCircuito: rutina
      }, ()=>{this.setState({modal:true})} );
    }
  }

  async rutinaDone() {
    let userObj = Parse.User.current();
    userObj.set("parcialRutinas", userObj.get("parcialRutinas") + 1);
    await userObj.save();
    let porcentaje = null
    if(userObj.get("totalRutinas"))
      porcentaje = (userObj.get("parcialRutinas")*100/userObj.get("totalRutinas")).toFixed(1);

    this.setState(prevState => ({
      modal: !prevState.modal,
      porcentaje: porcentaje,
      modal2: !prevState.modal2
    }));
  }
  componentWillReceiveProps(nextProps){
    let user = nextProps.user;
    this.setState({
      fullname: user.fullname,
      email: user.email,
      nit: user.nit,
      telefono: user.telefono,
      fechainicio: user.fechainicio,
      fechacorte: user.fechacorte,
      objetivo: user.objetivo,
      user: user,
      sexo: user.sexo,
      loading: false,
      loadingAvatar: false,
      editMode: false
    });
  }
  async componentDidMount() {
    if(this.props && this.props.user){
      let user = this.props.user;
      this.setState({
        fullname: user.fullname,
        email: user.email,
        nit: user.nit,
        telefono: user.telefono,
        fechainicio: user.fechainicio,
        fechacorte: user.fechacorte,
        objetivo: user.objetivo,
        user: user
      })
    }
    
    if(userObj && (userObj.get("conjunto") === "onlinegratis2" || userObj.get("conjunto") === "onlinegratis")){
      const queryU = new Parse.Query(Parse.User);
      queryU.equalTo("conjunto", "onlinegratis");  // find all the women
      queryU.limit(500);
      const women = await queryU.find();

      const queryAdmin = new Parse.Query(Parse.User)
      queryAdmin.get("YDryQ4IroC");
      const admin = await queryAdmin.first();
      this.setState({mycomunidad: women, admin })
    }

    getEjercicios().then(ejercicios => {
      this.setState({
        ejercicios: ejercicios.results
      });
    });

    if(userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis"){
      getMisRutinas().then(results => {
        let result = JSON.parse(results);
        this.setState({ rutinas: result.results });
        console.log(result.results);
      });
    }else{
      getMisRutinasOnline("bajo").then(results => {
        let result = JSON.parse(results);
        this.setState({ rutinasBajo: result.results });
      });
      getMisRutinasOnline("medio").then(results => {
        let result = JSON.parse(results);
        this.setState({ rutinasMedio: result.results });
      });
      getMisRutinasOnline("alto").then(results => {
        let result = JSON.parse(results);
        this.setState({ rutinasAlto: result.results });
      });
    }

    getTips("Nutricion").then(tips => {
      let tipsAr = JSON.parse(tips);
      this.setState({ tips: tipsAr.results})
    })
  }

  getCircuito(circuito){
    const ejercicios = this.state.ejercicios
    const self = this;
    return circuito.map(function(circuito) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      var match = circuito.ejercicioId.match(regExp);
      let index = ejercicios.findIndex(p => p.video === circuito.ejercicioId)
      let ejercicio = ejercicios[index]
       if (match && match[2].length === 11) {
        return (
          <div className="row">
            <div className="col-md-3">
              <img src={"https://img.youtube.com/vi/"+match[2]+"/1.jpg"} alt="thumbnail"/>
            </div>
            <div className="col-md-8">
              <h6>{ejercicio.nombre}</h6>
              <h6><strong>Repeticiones/Duración: </strong> x {circuito.repeticiones}</h6>
              <button className="btn btn-outline-success" onClick={()=>{
                self.setState({
                  modal3: true,
                  iframe: match[2]
                })
              }}>Ver Video</button>
            </div>
          </div>
        )
      }
    })
  }
  getvideo(id){
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    var match = id.match(regExp);
     if (match && match[2].length === 11) {
      return (
        <div>
          <iframe title={match[2]} width="300" height="250" src={"//www.youtube.com/embed/"+match[2]} frameborder="0" allowfullscreen></iframe>
        </div>
      )
    }
  }
  render() {
    const { rutinaCircuito } = this.state;

    let fechacorte = this.state.user && this.state.user.fechacorte_real ? this.state.user.fechacorte_real.iso : "";
    const fechaSplited = fechacorte.split("T");
    const fechaArray = fechaSplited[0].split("-");
    fechacorte = fechaArray[2] + "/" + parseInt(fechaArray[1]) + "/" + fechaArray[0];
    let avatar = MasculinoAvatar

    if(this.state.sexo === "Femenino")
      avatar = FemeninoAvatar

    if(this.state.user && this.state.user.avatar)
      avatar = this.state.user.avatar

    return (
      <div id="page-wrap">
        <header className="App-header">
          <img src={logoBlanco} className="App-logo" alt="logo" />
        </header>
        <div className="container emp-profile">
          <div className="row">
            <div className="col-md-4">
                <div className="profile-img">
                    <img src={avatar} alt=""/>
                </div>
            </div>
            <div className="col-md-6">
              <div className="profile-head">
                <h5>
                    {this.state.fullname}
                </h5>
                <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
                  Cerrar Sesión
                </button>
                <br/>
                <h6 style={{cursor:'pointer'}} onClick={() => this.setState({modal: true})}>
                      {this.state.objetivo && this.state.objetivo.length ? this.state.objetivo : "No has ingresado tu objetivo general"}
                </h6>
                <p className="proile-rating">NÚMERO DE ENTRENAMIENTOS REALIZADOS : <span>{this.state.asistencias.length}</span></p>
                <button className="btn btn-outline-success"  onClick={() => this.setState({modal2: true})} >Califica tu último entrenamiento</button>
                <Modal open={this.state.modal2} onClose={() => this.setState({modal2: false})} center>
                  <h5>{this.state.rutinaTitle}</h5>
                  <div className="form-group shadow-textarea">
                    <CalificarForm asistencia={this.state.ultimaAsistencia} />
                  </div>
                </Modal>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Entrenamientos Online</a>
                    </li>
                </ul>
              </div>
              <div className="col-md-12">
                <p className="category">
                  Entrena desde la comodidad de donde te encuentres y coméntale a tu entrenador como
                  te fué con ellos. Esto nos ayuda a ajustar nuestras rutinas
                  a tu medida.
                </p>
              </div>
            </div>
            <div className="col-md-2">

            </div>
          </div>
        </div>
        {this.state.rutinas ? (
        <div className="container emp-profile">
          <div className="row">
          <div className="col-md-12">
            <h5 className="card-title">RUTINA PERSONALIZADA</h5>
            <p>
              No entrenes la misma rutina por días consecutivos, úsalas en orden, un día la #1 al siguiente la #2 y al siguiente la #3.
              Entrena 1 día y descansa 1 día si te sientes muy agotado, de lo contrario puedes entrenar todos los días llevando la secuencia entre rutinas.
              La mayoría de ejercicios tienen un número de repeticiones, siéntete libre de hacer siempre 1 o 2 menos de lo que creas que es tu máximo. No necesitas
              dar tu 100% en esta etapa para lograr resultados.
            </p>
          </div>
            {userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" ? (
              <div className="col-md-12">
                <br/>
                <h4>Fecha de vencimiento: {fechacorte}</h4>
              </div>
            ) : null }
            {this.state.rutinas.map(rutina => (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{rutina.Titulo}</h5>
                    <p>
                      <strong>Objetivo: </strong>
                      {rutina.Nivel}
                    </p>
                    <p>
                      <strong>Dificultad: </strong>
                      {rutina.Level}
                    </p>
                    <button onClick={() => {
                      this.verRutina(rutina)
                    }} className="btn btn-outline-success" >Entrenar</button>
                  </div>
                </div>
                <br/>
              </div>
              ))}
          </div>
        </div>
        ) : null}
        {this.state.rutinasBajo ? (
        <div className="container emp-profile">
          <div className="row">
          <div className="col-md-12">
            <h5 className="card-title">NIVEL PRINCIPIANTE</h5>
            <p>Nunca he entrenado o estoy muy fuera de forma / Mi condición no es atlética</p>
            <p>Realiza la rutina #1 y en tu siguiente entrenamiento la rutina #2, luego repite pero no
                realices la misma rutina 2 días seguidos.</p>
            <p>
              Entrena 1 día y descansa 1 o 2 días para que vayas logrando adaptaciones y mejoras en tu cuerpo.
              Si sientes que quedas muy agotado con los ejercicios o no logras finalizarlos, disminuye las repeticiones
              hasta un número en el cual te sientas ejercitándote mas no sintiéndote mal. Si tus músculos duelen a causa del
              entrenamiento anterior, descansa siempre y cuando el dolor sea intolerable o molesto para volver a entrenar.
              En éste caso realiza sólo la rutina de estiramiento que aparece en cualquiera de las rutinas como circuito #4.
            </p>
          </div>
            {userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" ? (
              <div className="col-md-12">
                <br/>
                <h4>Fecha de vencimiento: {fechacorte}</h4>
              </div>
            ) : null }
            {this.state.rutinasBajo.map(rutina => (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{rutina.Titulo}</h5>
                    <p>
                      <strong>Objetivo: </strong>
                      {rutina.Nivel}
                    </p>
                    <p>
                      <strong>Dificultad: </strong>
                      {rutina.Level}
                    </p>
                    <button onClick={() => {
                      this.verRutina(rutina)
                    }} className="btn btn-outline-success" >Entrenar</button>
                  </div>
                </div>
                <br/>
              </div>
              ))}
          </div>
        </div>
        ) : null}
        {this.state.rutinasMedio ? (
        <div className="container emp-profile">
          <div className="row">
          <div className="col-md-12">
            <h5 className="card-title">NIVEL INTERMEDIO</h5>
            <p>He entrenado por más de 6 meses y menos de 1 año / Realizo actividad física pero no soy constante / Mi condición es semi-atlética</p>
            <p>
              No entrenes la misma rutina por días consecutivos, úsalas en orden, un día la #1 al siguiente la #2 y al siguiente la #3.
              Entrena 1 día y descansa 1 día si te sientes muy agotado, de lo contrario puedes entrenar todos los días llevando la secuencia entre rutinas.
              La mayoría de ejercicios tienen un rango de repeticiones, siéntete libre de hacer siempre 1 o 2 menos de lo que creas que es tu máximo. No necesitas
              dar tu 100% en esta etapa para lograr resultados.
            </p>
          </div>
            {userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" ? (
              <div className="col-md-12">
                <br/>
                <h4>Fecha de vencimiento: {fechacorte}</h4>
              </div>
            ) : null }
            {this.state.rutinasMedio.map(rutina => (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{rutina.Titulo}</h5>
                    <p>
                      <strong>Objetivo: </strong>
                      {rutina.Nivel}
                    </p>
                    <p>
                      <strong>Dificultad: </strong>
                      {rutina.Level}
                    </p>
                    <button onClick={() => {
                      this.verRutina(rutina)
                    }} className="btn btn-outline-success" >Entrenar</button>
                  </div>
                </div>
                <br/>
              </div>
              ))}
          </div>
        </div>
        ) : null}
        {this.state.rutinasAlto ? (
        <div className="container emp-profile">
          <div className="row">
          <div className="col-md-12">
            <h5 className="card-title">NIVEL AVANZADO</h5>
            <p>He entrenado por más de 1 año / Mi condición es atlética</p>
            <p>
              No entrenes la misma rutina por días consecutivos, úsalas en orden, un día la #1 al siguiente la #2 y al siguiente la #3.
              Entrena 1 día y descansa 1 día si te sientes muy agotado, de lo contrario puedes entrenar todos los días llevando la secuencia entre rutinas.
              La mayoría de ejercicios tienen un rango de repeticiones, siéntete libre de hacer siempre 1 o 2 menos de lo que creas que es tu máximo. No necesitas
              dar tu 100% en esta etapa para lograr resultados pero intenta buscar un progreso cada vez que repitas una rutina.
            </p>
          </div>
            {userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" ? (
              <div className="col-md-12">
                <br/>
                <h4>Fecha de vencimiento: {fechacorte}</h4>
              </div>
            ) : null }
            {this.state.rutinasAlto.map(rutina => (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{rutina.Titulo}</h5>
                    <p>
                      <strong>Objetivo: </strong>
                      {rutina.Nivel}
                    </p>
                    <p>
                      <strong>Dificultad: </strong>
                      {rutina.Level}
                    </p>
                    <button onClick={() => {
                      this.verRutina(rutina)
                    }} className="btn btn-outline-success" >Entrenar</button>
                  </div>
                </div>
                <br/>
              </div>
              ))}
          </div>
        </div>
        ) : null}
        <div className="container emp-profile">
          <div className="col-md-12">
            <h5 className="card-title">TIPS DE ENTRENAMIENTO</h5>
          </div>
          <div style={{"padding":"0 60px","maxWidth":800,"margin":"0 auto", width: "100vw"}}>
          {window.innerWidth <= 800 ? (
            <ItemsCarousel
              gutter={12}
              activePosition={'center'}
              chevronWidth={60}
              numberOfCards={1}
              slidesToScroll={1}
              outsideChevron={true}
              showSlither={false}
              firstAndLastGutter={false}
              activeItemIndex={this.state.activeItemIndex}
              requestToChangeActive={value => this.setState({ activeItemIndex: value })}
              rightChevron={<button className="btn btn-outline-success">{'>'}</button>}
              leftChevron={<button className="btn btn-outline-success">{'<'}</button>}
            >
              {this.state.tips &&  this.state.tips.map(tip =>
                <div
                  key={tip.objectId}
                  style={{
                    height: 350,
                    background: "#EEE",
                  }}
                >

                  <h5 className="text-center"><br/><img src={trainIcon} width="60" alt={tip.title} /></h5>
                  <h5 className="text-center"><br/>{tip.title}</h5>
                  <p style={{
                    padding: 10,
                    fontSize: 12,
                  }} >
                    {tip.resumen}
                    <br/>
                    {tip.cuerpo ? (<button onClick={() => {
                      this.verTip(tip)
                    }} className="btn btn-outline-success">Ver más</button>) : (null)}
                  </p>
                </div>
              )}
            </ItemsCarousel>
          ):(
            <ItemsCarousel
              gutter={12}
              activePosition={'center'}
              chevronWidth={60}
              numberOfCards={2}
              slidesToScroll={2}
              outsideChevron={true}
              showSlither={false}
              firstAndLastGutter={false}
              activeItemIndex={this.state.activeItemIndex}
              requestToChangeActive={value => this.setState({ activeItemIndex: value })}
              rightChevron={<button className="btn btn-outline-success">{'>'}</button>}
              leftChevron={<button className="btn btn-outline-success">{'<'}</button>}
            >
              {this.state.tips &&  this.state.tips.map(tip =>
                <div
                  key={tip.objectId}
                  style={{
                    height: 350,
                    background: "#EEE",
                  }}
                >

                  <h5 className="text-center"><br/><img src={trainIcon} width="60" alt={tip.title} /></h5>
                  <h5 className="text-center"><br/>{tip.title}</h5>
                  <p style={{
                    padding: 10,
                    fontSize: 12,
                  }} >
                    {tip.resumen}
                    <br/>
                    {tip.cuerpo ? (<button onClick={() => {
                      this.verTip(tip)
                    }} className="btn btn-outline-success">Ver más</button>) : (null)}
                  </p>
                </div>
              )}
            </ItemsCarousel>
          )}
          </div>
        </div>
          <Modal open={this.state.modal} onClose={() => this.setState({modal: false})} center>
          <h5>{this.state.rutinaTitle}</h5>
          {this.state.rutinaBody ? (
            <div>{this.state.rutinaBody}</div>
          ) : (
            <div>
              {rutinaCircuito && rutinaCircuito.Comentario_circuito_1 ? (
                <div>
                  <h4>Circuito 1</h4>
                  {rutinaCircuito.Comentario_circuito_1} <br/>

                    {this.getCircuito(rutinaCircuito.Circuito_1)}

                </div>
              ) : (
                <div></div>
              )}
              {rutinaCircuito && rutinaCircuito.Comentario_circuito_2 ? (
                <div>
                  <h4>Circuito 2</h4>
                  {rutinaCircuito.Comentario_circuito_2} <br/>


                    {this.getCircuito(rutinaCircuito.Circuito_2)}

                </div>
              ) : (
                <div></div>
              )}
              {rutinaCircuito && rutinaCircuito.Comentario_circuito_3 ? (
                <div>
                  <h4>Circuito 3</h4>
                  {rutinaCircuito.Comentario_circuito_3} <br/>


                    {this.getCircuito(rutinaCircuito.Circuito_3)}

                </div>
              ) : (
                <div></div>
              )}
              {rutinaCircuito && rutinaCircuito.Comentario_circuito_4 ? (
                <div>
                  <h4>Circuito 4</h4>
                  {rutinaCircuito.Comentario_circuito_4} <br/>


                    {this.getCircuito(rutinaCircuito.Circuito_4)}

                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
          </Modal>
          <Modal styles={modalstyle} open={this.state.modal3} onClose={() => this.setState({modal3: false})} center>
            <div className="videoWrapper">
              <iframe title="iframe" width="100%" height="auto" src={"//www.youtube.com/embed/"+this.state.iframe} frameborder="0" allowfullscreen></iframe>
            </div>
          </Modal>
          <Modal styles={this.state.modalstyle} open={this.state.modal4} onClose={() => this.setState({modal4: false})} center>
          <h5 className="card-title">{this.state.recetaTitle}</h5>
          <br/>
          {this.state.recetaBody}
          </Modal>
        </div>

    );
  }
}
