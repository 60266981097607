import React from 'react';
import logoBlanco from "./Images/logoBlancox3.png";
import MasculinoAvatar from './Images/Masculino.svg';
import FemeninoAvatar from './Images/Femenino.svg';
import './App.css';
import { getAliados, getTips } from "./actions/AgendaHelper";
import ReactHtmlParser from 'react-html-parser';
import Modal from 'react-responsive-modal';
import Parse from "parse";
import ItemsCarousel from 'react-items-carousel';
import FoodIcon from './Images/diet.svg';
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";
const userObj = Parse.User.current();
const modalstyle = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "center"
  }
};
const modalstyle2 = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "justify",
    'padding': 20
  }
};

export default class Aliados extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      asistencias: [],
      myranking: 0,
      loading: true,
      modal: false,
      modal2: false,
      modal3: false,
      recetas: null,
      recetaTitle: "",
      recetaBody: "",
      menu: [],
      carbos: "",
      protes: "",
      fats: "",
      alimentos: [],
      children: [],
      activeItemIndex: 0,
      tips: [],
      modalstyle: null
    };
    this.verTip = this.verTip.bind(this)
  }



  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }
  verTip(tip){
    this.setState({
      modal3: true,
      recetaTitle: tip.title,
      modalstyle: modalstyle2,
      recetaBody: ReactHtmlParser(tip.cuerpo.replace(/\\"/g, '"'))
    });
  }

  
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name);
    console.log(value);
    this.setState({
      [name]: value
    });
  }

  componentWillReceiveProps(nextProps){
    let user = nextProps.user;
    this.setState({
      fullname: user.fullname,
      email: user.email,
      nit: user.nit,
      telefono: user.telefono,
      fechainicio: user.fechainicio,
      fechacorte: user.fechacorte,
      objetivo: user.objetivo,
      user: user
    })
  }

  componentDidMount() {
    if(this.props && this.props.user){
      let user = this.props.user;
      this.setState({
        fullname: user.fullname,
        email: user.email,
        nit: user.nit,
        telefono: user.telefono,
        fechainicio: user.fechainicio,
        fechacorte: user.fechacorte,
        objetivo: user.objetivo,
        user: user
      })
    }

    getAliados().then(results => {
        let result = JSON.parse(results);
        let recetas = result.results
        this.setState({
          loading: false,
          recetas: recetas
        });
      });


    
    getTips("Nutricion").then(tips => {
      let tipsAr = JSON.parse(tips);
      this.setState({ tips: tipsAr.results})
    })
  }

  render() {


    let fechacorte = this.state.user && this.state.user.fechacorte_real ? this.state.user.fechacorte_real.iso : "";
    const fechaSplited = fechacorte.split("T");
    const fechaArray = fechaSplited[0].split("-");
    fechacorte = fechaArray[2] + "/" + parseInt(fechaArray[1]) + "/" + fechaArray[0];

    let avatar = MasculinoAvatar

    if(this.state.sexo === "Femenino")
      avatar = FemeninoAvatar

    if(this.state.user && this.state.user.avatar)
      avatar = this.state.user.avatar

    return (
      <div id="page-wrap">
        <header className="App-header">
          <img src={logoBlanco} className="App-logo" alt="logo" />
        </header>
        <div className="container emp-profile">

                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-img">
                            <img src={avatar} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head">
                          <h5>
                              {this.state.fullname}
                          </h5>
                          <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
                            Cerrar Sesión
                          </button>
                          <br/>
                          <h6 style={{cursor:'pointer'}} onClick={() => this.setState({modal: true})}>
                                {this.state.objetivo && this.state.objetivo.length ? this.state.objetivo : "No has ingresado tu objetivo general"}
                          </h6>
                          <Modal open={this.state.modal} onClose={() => this.setState({modal: false})} center>
                            <h5>Objetivo General</h5>
                            <div className="form-group shadow-textarea">
                              <label for="exampleFormControlTextarea6">Ingresa una descripción de tu objetivo general con VES</label>
                              <textarea className="form-control z-depth-1" name="objetivo" onChange={this.handleInputChange.bind(this)} id="exampleFormControlTextarea6" rows="3" placeholder="Mejorar mi salud...">
                                {this.state.objetivo}
                              </textarea>
                              <br/>
                              <button onClick={()=>{
                                let user = Parse.User.current();
                                if(this.state.objetivo.length){
                                  user.set("objetivo", this.state.objetivo);
                                  user.save().then(()=>{
                                    this.props.getUserData()
                                  });
                                  this.setState({modal: false})
                                }else{
                                  alert("Ingrese su objetivo por favor")
                                }

                              }} type="button" className="btn btn-outline-success">Guardar objetivo</button>
                            </div>
                          </Modal>
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item">
                                  <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Beneficios</a>
                              </li>
                          </ul>
                        </div>
                        <div className="col-md-12">
                        {userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" ? (
                          <p className="category">
                          Conoce aquí a nuestros aliados y obtén descuentos y beneficios con cada uno de nuestros Aliados
                          </p>
                        ) : (
                          <p className="category">
                          ¡Hola!<br/>

La primera tarea que tenemos para esta semana será:
<br/><br/>
Hacer un diario por 3 días con los siguientes puntos:
<br/><br/>
- Horario de la comida<br/>
- Exactamente que fue lo que comí, así sea un dulcesito<br/>
- En cuales condiciones: ¿despacio o de afán? ¿tenía ansiedad o algún sentimiento especifico en este momento?<br/>
- ¿Tenía hambre, o comí sin hambre?<br/>
- ¿Cuanto dulce como al día? ¿cuantos paquetes? ¿cuánta comida chatarra como a la semana? ¿cuánta gaseosa?<br/>
- ¿Tomo cuanto de agua o no tomo agua?<br/>
<br/><br/>
Todo eso lo vas a llevar por 3 días en apuntes en una hoja o en tu celular para que de esta manera tengas un panorama consciente de como te alimentas.
                          </p>
                        ) }

                        </div>
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <br/>
                    {this.state.user && this.state.user.cuerpo ? ReactHtmlParser(this.state.user.cuerpo.replace(/\\"/g, '"')) : ""}
                  </div>
                  <br/>
                  <div className="col-md-12">
                    <h5 className="card-title"><br/>Aliados</h5>
                  </div>
                  {this.state.recetas && this.state.recetas.map(comida => (
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                            <h5 className="text-center">
                                <img src={comida.fotourl} width="150" alt={comida.title} />
                            </h5>
                          <h5 className="card-title">
                            {comida.title}
                          </h5>
                          <p>
                            {comida.cuerpo ? ReactHtmlParser(comida.cuerpo.replace(/\\"/g, '"')) : ""}
                            </p>
                            <hr/>
                        </div>
                      </div>
                      <br/>
                    </div>
                  ))}
                  <div className="col-md-12">
                    <h5 className="card-title">TIPS</h5>
                  </div>
                  <div style={{"padding":"0 60px","maxWidth":800,"margin":"0 auto", width: "100vw"}}>
                  {window.innerWidth <= 800 ? (
                    <ItemsCarousel
                      gutter={12}
                      activePosition={'center'}
                      chevronWidth={60}
                      numberOfCards={1}
                      slidesToScroll={1}
                      outsideChevron={true}
                      showSlither={false}
                      firstAndLastGutter={false}
                      activeItemIndex={this.state.activeItemIndex}
                      requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                      rightChevron={<button className="btn btn-outline-success">{'>'}</button>}
                      leftChevron={<button className="btn btn-outline-success">{'<'}</button>}
                    >
                      {this.state.tips &&  this.state.tips.map(tip =>
                        <div
                          key={tip.objectId}
                          style={{
                            height: 350,
                            background: "#EEE",
                          }}
                        >

                          <h5 className="text-center"><br/><img src={FoodIcon} width="60" alt={tip.title} /></h5>
                          <h5 className="text-center"><br/>{tip.title}</h5>
                          <p style={{
                            padding: 10,
                            fontSize: 12,
                          }} >
                            {tip.resumen}
                            <br/>
                            {tip.cuerpo ? (<button onClick={() => {
                              this.verTip(tip)
                            }} className="btn btn-outline-success">Ver más</button>) : (null)}
                          </p>
                        </div>
                      )}
                    </ItemsCarousel>
                  ):(
                    <ItemsCarousel
                      gutter={12}
                      activePosition={'center'}
                      chevronWidth={60}
                      numberOfCards={2}
                      slidesToScroll={2}
                      outsideChevron={true}
                      showSlither={false}
                      firstAndLastGutter={false}
                      activeItemIndex={this.state.activeItemIndex}
                      requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                      rightChevron={<button className="btn btn-outline-success">{'>'}</button>}
                      leftChevron={<button className="btn btn-outline-success">{'<'}</button>}
                    >
                      {this.state.tips &&  this.state.tips.map(tip =>
                        <div
                          key={tip.objectId}
                          style={{
                            height: 350,
                            background: "#EEE",
                          }}
                        >

                          <h5 className="text-center"><br/><img src={FoodIcon} width="60" alt={tip.title} /></h5>
                          <h5 className="text-center"><br/>{tip.title}</h5>
                          <p style={{
                            padding: 10,
                            fontSize: 12,
                          }} >
                            {tip.resumen}
                            <br/>
                            {tip.cuerpo ? (<button onClick={() => {
                              this.verTip(tip)
                            }} className="btn btn-outline-success">Ver más</button>) : (null)}
                          </p>
                        </div>
                      )}
                    </ItemsCarousel>
                  )}
                  </div>
                  <br/>
                </div>
          </div>
          <Modal styles={this.state.modalstyle} open={this.state.modal3} onClose={() => this.setState({modal3: false})} center>
          <h5 className="card-title">{this.state.recetaTitle}</h5>
          <br/>
          {this.state.recetaBody}
          </Modal>
        </div>

    );
  }
}
