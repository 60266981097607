import React from 'react';
import logoBlanco from "./Images/logoBlancox3.png";
import MasculinoAvatar from './Images/Masculino.svg';
import FemeninoAvatar from './Images/Femenino.svg';
import addpic from './Images/addpic.svg';
import Loading from './Images/loading.gif';
import './App.css';
import { getChat, getTips } from "./actions/AgendaHelper";
import ReactHtmlParser from 'react-html-parser';
import Modal from 'react-responsive-modal';
import Parse from "parse";
import "react-datepicker/dist/react-datepicker.css";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";

const modalstyle2 = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "justify",
    'padding': 20
  }
};

export default class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      asistencias: [],
      myranking: 0,
      loading: false,
      modal: false,
      modal2: false,
      modal3: false,
      startDate: new Date(),
      recetaTitle: "",
      recetaBody: "",
      activeItemIndex: 0,
      tips: [],
      chat: [],
      modalstyle: null,
      body: null,
      addpic: addpic,
      loadingAvatar: Loading,
      subject: "nutricion"
    };
    this.verTip = this.verTip.bind(this)
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });
  }

  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        // window.location.reload();
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        // window.location.reload();
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }
  verTip(tip){
    this.setState({
      modal3: true,
      recetaTitle: tip.title,
      modalstyle: modalstyle2,
      recetaBody: ReactHtmlParser(tip.cuerpo.replace(/\\"/g, '"'))
    });
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name);
    console.log(value);
    this.setState({
      [name]: value
    });
  }

  componentWillReceiveProps(nextProps){
    let user = nextProps.user;
    this.setState({
      fullname: user.fullname,
      email: user.email,
      nit: user.nit,
      telefono: user.telefono,
      fechainicio: user.fechainicio,
      fechacorte: user.fechacorte,
      objetivo: user.objetivo,
      user: user
    })
  }

  componentDidMount() {
    if(this.props && this.props.user){
      let user = this.props.user;
      this.setState({
        fullname: user.fullname,
        email: user.email,
        nit: user.nit,
        telefono: user.telefono,
        fechainicio: user.fechainicio,
        fechacorte: user.fechacorte,
        objetivo: user.objetivo,
        user: user
      })
    }
    // this.setState({
    //   email: user.email,
    //   nit: user.nit,
    //   telefono: user.telefono,
    //   fechainicio: user.fechainicio,
    //   fechacorte: user.fechacorte,
    //   objetivo: user.objetivo,
    //   fullname: user.fullname,
    //   user: user,
    //   sexo: user.sexo,
    // })

    getTips(null).then(tips => {
      let tipsAr = JSON.parse(tips);
      this.setState({ tips: tipsAr.results})
    })

    getChat("nutricion").then(results => {
      let result = JSON.parse(results);
      this.setState({
        loading: false,
        chat: result.results
      }, ()=>{
        this.el.scrollIntoView({ behavior: 'smooth' });
        this.el.scrollTop = this.el.scrollHeight;
      });
    });
  }

  componentDidUpdate(){
    this.el.scrollIntoView({ behavior: 'smooth' });
    this.el.scrollTop = this.el.scrollHeight;
  }

  handleSubmit(e){
    e.preventDefault();
    const self = this;
    console.log(self.state);
    let userObj = Parse.User.current();
    userObj = userObj._toFullJSON();

    var xhttp = new XMLHttpRequest();
    xhttp.open(
      "POST",
      "https://miembros.vescolombia.com.co/parse/classes/Chat",
      true);
    xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp.setRequestHeader("X-Parse-Session-Token", userObj.sessionToken);
    xhttp.onload = function() {
      // alert("Mensaje enviado con éxito");
      getChat(self.state.subject).then(results => {
        let result = JSON.parse(results);
        self.setState({
          loading: false,
          body: "",
          chat: result.results
        }, ()=>{
          self.el.scrollIntoView({ behavior: 'smooth' });
          self.el.scrollTop = self.el.scrollHeight;
        });
      });
    };
    //sessionToken
    xhttp.send(
      '{"body":"' +
        self.state.body +
        '", "subject":"' +
        self.state.subject +
        '", "userId":"' +
        userObj.objectId +
      '"}'
    );

    /*************************Envío Mail****************************/
    let html = "<h5 className='card-title'>Tienes una nueva comunicación del cliente: "+this.state.fullname+"</h5>"+
    "<p><strong>Comentario</strong></p><p>" + self.state.body + "</p>";
    var xhttp2 = new XMLHttpRequest();
    xhttp2.open(
      "POST",
      "https://miembros.vescolombia.com.co/parse/functions/enviomail",
      true);
    xhttp2.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
    xhttp2.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
    xhttp2.setRequestHeader("X-Parse-Session-Token", userObj.sessionToken);
    xhttp2.onload = function() {
      console.log("confirmado");
      console.log(xhttp2.response);
    };
    //sessionToken
    xhttp2.send(
      '{"emailTo": "notificacionesves@gmail.com", ' +
        '"subject":"' +
        self.state.subject +
        '", "html":"' +
        html +
      '"}'
    );
  }

  render() {


    let fechacorte = this.state.user && this.state.user.fechacorte_real ? this.state.user.fechacorte_real.iso : "";
    const fechaSplited = fechacorte.split("T");
    const fechaArray = fechaSplited[0].split("-");
    fechacorte = fechaArray[2] + "/" + parseInt(fechaArray[1]) + "/" + fechaArray[0];

    let avatar = MasculinoAvatar

    if(this.state.sexo === "Femenino")
      avatar = FemeninoAvatar

    if(this.state.user && this.state.user.avatar)
      avatar = this.state.user.avatar

    let meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"]



    return (
      <div id="page-wrap">
        <header className="App-header">
          <img src={logoBlanco} className="App-logo" alt="logo" />
        </header>
        <div className="container emp-profile">

                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-img">
                            <img src={avatar} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head">
                          <h5>
                              {this.state.fullname}
                          </h5>
                          <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
                            Cerrar Sesión
                          </button>
                          <br/>
                          <h6 style={{cursor:'pointer'}} onClick={() => this.setState({modal: true})}>
                                {this.state.objetivo && this.state.objetivo.length ? this.state.objetivo : "No has ingresado tu objetivo general"}
                          </h6>
                          <Modal open={this.state.modal} onClose={() => this.setState({modal: false})} center>
                            <h5>Objetivo General</h5>
                            <div className="form-group shadow-textarea">
                              <label>Ingresa una descripción de tu objetivo general con VES</label>
                              <textarea className="form-control z-depth-1" name="objetivo" onChange={this.handleInputChange.bind(this)} id="exampleFormControlTextarea6" rows="3" placeholder="Mejorar mi salud...">
                                {this.state.objetivo}
                              </textarea>
                              <br/>
                              <button onClick={()=>{
                                let user = Parse.User.current();
                                if(this.state.objetivo.length){
                                  user.set("objetivo", this.state.objetivo);
                                  user.save().then(()=>{
                                    this.props.getUserData()
                                  });
                                  this.setState({modal: false})
                                }else{
                                  alert("Ingrese su objetivo por favor")
                                }

                              }} type="button" className="btn btn-outline-success">Guardar objetivo</button>
                            </div>
                          </Modal>
                          <p className="proile-rating">NÚMERO DE ENTRENAMIENTOS REALIZADOS : <span>{this.state.asistencias.length}</span></p>
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item">
                                  <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Chat de contacto</a>
                              </li>
                          </ul>
                        </div>
                        <div className="col-md-12">
                          <p className="category">
                            ¿Tienes preguntas? Envíanos todas tus dudas, dinos cuales alimentos quisieras cambiar o cuales ejercicios. Juntos vamos a cumplir tus metas!
                          </p>
                        </div>
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>
                <div className="col-md-6 offset-md-3">
                  <h5 className="card-title">Estemos en contacto</h5>
                </div>
                <div className="containerMsg">
                  <h3 className=" text-center">Mensajes</h3>
                  <div className="messaging">
                        <div className="inbox_msg">
                          <div className="inbox_people">
                            <div className="inbox_chat">

                              <div style={{cursor:"pointer"}} onClick={()=>{
                                this.setState({
                                  subject: "nutricion"
                                }, ()=>{
                                  getChat(this.state.subject).then(results => {
                                    let result = JSON.parse(results);
                                    this.setState({
                                      loading: false,
                                      chat: result.results
                                    }, ()=>{
                                      this.el.scrollIntoView({ behavior: 'smooth' });
                                      this.el.scrollTop = this.el.scrollHeight;
                                    });
                                  });
                                })
                              }} className={this.state.subject==="nutricion" ? "active_chat chat_list" : "chat_list"}>
                                <div className="chat_people">
                                  <div className="chat_img"> <img src={logoBlanco} alt="avatar" /> </div>
                                  <div className="chat_ib">
                                    <h5>Nutrición</h5>
                                    <p>Haz clic aquí para que veas los mensajes relacionados con tu nutrición</p>
                                  </div>
                                </div>
                              </div>

                              <div style={{cursor:"pointer"}} onClick={()=>{
                                this.setState({
                                  subject: "entrenamiento"
                                }, ()=>{
                                  getChat(this.state.subject).then(results => {
                                    let result = JSON.parse(results);
                                    this.setState({
                                      loading: false,
                                      chat: result.results
                                    }, ()=>{
                                      this.el.scrollIntoView({ behavior: 'smooth' });
                                      this.el.scrollTop = this.el.scrollHeight;
                                    });
                                  });
                                })
                              }} className={this.state.subject==="entrenamiento" ? "active_chat chat_list" : "chat_list"}>
                                <div className="chat_people">
                                  <div className="chat_img"> <img src={logoBlanco} alt="avatar" /> </div>
                                  <div className="chat_ib">
                                    <h5>Entrenamientos</h5>
                                    <p>Haz clic aquí para que veas los mensajes relacionados con tus entrenamientos</p>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="mesgs">
                            <div ref={el => { this.el = el; }} className="msg_history">

                            {this.state.chat.map(receta => {
                              let fecha = new Date(receta.createdAt);
                              return receta.incoming_msg!=="SI"?(
                              <div key={receta.objectId} className="outgoing_msg">
                                <div className="sent_msg">
                                  <p>{receta.body}</p>
                                  <span className="time_date"> {fecha.getHours()}:{fecha.getMinutes()}    |    {meses[fecha.getMonth()]} {fecha.getDate()}</span> </div>
                              </div>
                            ):(
                              <div key={receta.objectId} className="incoming_msg">
                                <div className="incoming_msg_img"> <img src={logoBlanco} alt="avatar" /> </div>
                                <div className="received_msg">
                                  <div className="received_withd_msg">
                                    <p>{receta.body}</p>
                                    <span className="time_date"> {fecha.getHours()}:{fecha.getMinutes()}    |    {meses[fecha.getMonth()]} {fecha.getDate()}</span></div>
                                </div>
                              </div>
                            )
                          })}
                            </div>
                            <div className="type_msg">
                              <div className="input_msg_write">
                              <form onSubmit={this.handleSubmit.bind(this)} >
                                <input type="text" className="write_msg" onChange={this.handleInputChange.bind(this)} name="body" placeholder="¿Qué nos quieres contar?" value={this.state.body?this.state.body:""}/>
                                <button onClick={this.handleSubmit.bind(this)} className="msg_send_btn" type="button"><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                              </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div></div>
          </div>
          <Modal styles={this.state.modalstyle} open={this.state.modal3} onClose={() => this.setState({modal3: false})} center>
          <h5 className="card-title">{this.state.recetaTitle}</h5>
          <br/>
          {this.state.recetaBody}
          </Modal>
        </div>

    );
  }
}
