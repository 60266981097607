import React from 'react';
import MasculinoAvatar from './Images/Masculino.svg';
import FemeninoAvatar from './Images/Femenino.svg';
import Loading from './Images/loading.gif';
import logoBlanco from "./Images/logoBlancox3.png";
import './App.css';
import { setUserInfo, getUserAsistencias, getAsistenciasRanking } from "./actions/DashboardHelper";
import Modal from 'react-responsive-modal';
import {Dropbox} from 'dropbox'
import Parse from "parse";
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";

const accessToken = 'DC3q4Q6sIwAAAAAAAAAAC6GtnNTJCqCERh-ap2KrdQSiDkBJwP-ROA7a7KTGd7Qc';
const dbx = new Dropbox({
  accessToken,
  fetch
});
const userObj = Parse.User.current();
export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullname: "",
      email: "",
      nit: "",
      telefono: "",
      fechainicio: "",
      fechacorte: "",
      myfat: [],
      mycomments: [],
      modal: false,
      modal2: false,
      modal3: false,
      medidaTitle: null,
      bone_mass: "",
      bmi: "",
      basal_metabolic_rate: "",
      BodyFat: "",
      Peso: "",
      skeletal_muscle: "",
      viceral_fat: "",
      comentariosNutricion: "",
      comentarios_deportologo: "",
      objetivo: "",
      ranking: 0,
      myranking: 0,
      user: null,
      editMode: false,
      sexo: null,
      startDate: new Date(),
      loading: true
    };
    this.handleChange = this.handleChange.bind(this);
    // this.toggle = this.toggle.bind(this);
    // this.verMedidas = this.verMedidas.bind(this);
  }
  handleChange(date) {
    this.setState({
      startDate: date
    });
  }
  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }
  componentWillReceiveProps(nextProps){
    let user = nextProps.user;
    this.setState({
      fullname: user.fullname,
      email: user.email,
      nit: user.nit,
      telefono: user.telefono,
      fechainicio: user.fechainicio,
      fechacorte: user.fechacorte,
      objetivo: user.objetivo,
      user: user,
      sexo: user.sexo,
      loading: false,
      loadingAvatar: false,
      editMode: false
    });
  }
  componentDidMount() {

    if(this.props && this.props.user){
      let user = this.props.user;
      this.setState({
        fullname: user.fullname,
        email: user.email,
        nit: user.nit,
        telefono: user.telefono,
        fechainicio: user.fechainicio,
        fechacorte: user.fechacorte,
        objetivo: user.objetivo,
        user: user
      })
    }
    if(userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis"){
      // getAsistenciasRanking().then(results => {
      //   let userObj = Parse.User.current();
      //   let resultados = JSON.parse(results);
      //   resultados.results.sort(function (a, b) {
      //     return a.asistencias - b.asistencias;
      //   });
      //   this.setState({ranking: resultados.results.reverse()}, () => {
      //     let positionR = this.state.ranking.findIndex(p => p.objectId === userObj.id)
      //     if(positionR !== -1)
      //       this.setState({myranking: positionR});
      //   });
      //
      // });
      if(userObj.get("fechainicio"))
        getUserAsistencias().then(results => {
          let result = JSON.parse(results);
          let asistencias = [];
          for (var i = 0; i < result.results.length; i++) {
            asistencias.push(result.results[i]);
          }
          this.setState({ ultimaAsistencia: asistencias[0] });
          this.setState({ asistencias });
        });
    }
  }
  handleFileChange(event) {
    this.setState({
      loadingAvatar: Loading
    });
    const self = this;
    self.setState({loading: true})
    var fileInput = document.getElementById('fotoPerfil');
    var file = fileInput.files[0];

    dbx.filesUpload({path: '/' + file.name, contents: file, mode: 'overwrite'})
    .then(function(response) {
      console.log(response.path_display);
      dbx.sharingCreateSharedLinkWithSettings({path: response.path_display})
      .then(function(responselink) {

         let user = Parse.User.current();
         user.set("avatar", responselink.url.replace('dl=0', 'raw=1'));
         user.save().then(()=>{
            alert("Foto actualizada")
            this.props.getUserData()
         });
       })
       .catch(function(error) {
         console.log(error);
       });
    })
    .catch(function(error) {
      console.error(error);
    });
    return false;
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name);
    console.log(value);
    this.setState({
      [name]: value
    });
  }
  handleSubmit(e) {
    e.preventDefault()
    let { fullname, telefono, email, nit, perfilCliente, sexo } = this.state;
    if(!perfilCliente)
      perfilCliente = this.state.user.perfilCliente;

    setUserInfo(fullname, telefono, email, nit, perfilCliente, sexo).then(results => {
      if(results){
        this.props.getUserData()
      }else{
        alert("ERROR Actualizando los datos")
      }
    });
  }

  render() {
    let fechacorte = this.state.user && this.state.user.fechacorte_real ? this.state.user.fechacorte_real.iso : "";

    const fechaSplited = fechacorte.split("T");
    const fechaArray = fechaSplited[0].split("-");
    fechacorte = fechaArray[2] + "/" + parseInt(fechaArray[1]) + "/" + fechaArray[0];

    let threemonth = new Date();
    let onemonth = new Date();
    onemonth.setMonth(onemonth.getMonth()+2);
    threemonth.setMonth(threemonth.getMonth()+4);
    let avatar = MasculinoAvatar
    if(this.state.sexo === "Femenino")
      avatar = FemeninoAvatar

    if(this.state.loadingAvatar){
      avatar = this.state.loadingAvatar
    }else
    if(this.state.user && this.state.user.avatar)
      avatar = this.state.user.avatar

    return (
      <div id="page-wrap">
        <header className="App-header">
          <img src={logoBlanco} className="App-logo" alt="logo" />
        </header>
        <div className="container emp-profile">

                <div className="row">
                    <div className="col-md-4">
                        <div style={{cursor:'pointer'}} onClick = { () => { document.getElementById("fotoPerfil").click() }} className="profile-img">
                            <img src={avatar} alt=""/>
                            <input className="fotoFile" id="fotoPerfil" type="file" name="fotoPerfil" onChange={this.handleFileChange.bind(this)} />
                        </div>
                        <p>Haga clic en la foto para cambiarla</p>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head">
                          <h5>
                              {this.state.fullname}
                          </h5>
                          <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
                            Cerrar Sesión
                          </button>
                          <br/>
                          <h6 style={{cursor:'pointer'}} onClick={() => this.setState({modal: true})}>
                                {this.state.objetivo && this.state.objetivo.length ? this.state.objetivo : "No has ingresado tu objetivo general"}
                          </h6>
                          <Modal open={this.state.modal} onClose={() => this.setState({modal: false})} center>
                            <h5>Objetivo General</h5>
                            <div className="form-group shadow-textarea">
                              <label for="exampleFormControlTextarea6">Ingresa una descripción de tu objetivo general con VES</label>
                              <textarea className="form-control z-depth-1" name="objetivo" onChange={this.handleInputChange.bind(this)} id="exampleFormControlTextarea6" rows="3" placeholder="Mejorar mi salud...">
                                {this.state.objetivo}
                              </textarea>
                              <br/>
                              <button onClick={()=>{
                                let user = Parse.User.current();
                                if(this.state.objetivo.length){
                                  user.set("objetivo", this.state.objetivo);
                                  user.save().then(()=>{
                                    this.props.getUserData()
                                  });
                                  this.setState({modal: false})
                                }else{
                                  alert("Ingrese su objetivo por favor")
                                }

                              }} type="button" className="btn btn-outline-success">Guardar objetivo</button>
                            </div>
                          </Modal>
                          {userObj && userObj.get("conjunto") !== "onlinegratis2" && userObj.get("conjunto") !== "onlinegratis" ? (
                            <p className="proile-rating">RANKING DE ASISTENCIA : <span>{this.state.myranking}/{this.state.ranking.length}</span></p>
                          ) : null}
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item">
                                  <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Sobre mi</a>
                              </li>
                          </ul>
                        </div>
                        <div className="tab-content profile-tab" id="myTabContent">
                          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <form onSubmit={this.handleSubmit.bind(this)}>
                              <div className="row">
                                  <div className="col-md-6">
                                      <label>Número de documento</label>
                                  </div>
                                  <div className="col-md-6">
                                    {this.state.editMode ? (
                                      <input  onChange={this.handleInputChange.bind(this)} name="nit" value={this.state.nit ? this.state.nit : ""} type="text" className="form-control" />
                                    ) : (
                                      <p style={{cursor:'pointer'}} onClick={() => this.setState({editMode: true})}>
                                        {this.state.nit ? this.state.nit : "Por favor ingresa tu número de documento"}
                                      </p>
                                    )}
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <label>Nombres y Apellidos</label>
                                  </div>
                                  <div className="col-md-6">
                                    {this.state.editMode ? (
                                      <input onChange={this.handleInputChange.bind(this)} name="fullname" value={this.state.fullname ? this.state.fullname : ""} type="text" className="form-control" />
                                    ) : (
                                      <p style={{cursor:'pointer'}} onClick={() => this.setState({editMode: true})}>
                                        {this.state.fullname ? this.state.fullname : "Por favor ingresa tu nombre completo"}
                                      </p>
                                    )}
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <label>Sexo</label>
                                  </div>
                                  <div className="col-md-6">
                                  {this.state.editMode ? (
                                    <select onChange={this.handleInputChange.bind(this)} name="sexo" type="text" className="form-control">
                                      <option value="Por favor ingresa tu sexo">Selecciona</option>
                                      <option value="Femenino">Femenino</option>
                                      <option value="Masculino">Masculino</option>
                                    </select>
                                  ) : (
                                    <p style={{cursor:'pointer'}} onClick={() => this.setState({editMode: true})}>
                                      {this.state.sexo ? this.state.sexo : "Por favor ingresa tu sexo"}
                                    </p>
                                  )}
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <label>Email</label>
                                  </div>
                                  <div className="col-md-6">
                                  {this.state.editMode ? (
                                    <input onChange={this.handleInputChange.bind(this)} name="email" value={this.state.email ? this.state.email : ""} type="text" className="form-control" />
                                  ) : (
                                    <p style={{cursor:'pointer'}} onClick={() => this.setState({editMode: true})}>
                                      {this.state.email ? this.state.email : "Por favor ingresa tu correo electrónico"}
                                    </p>
                                  )}
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <label>Teléfono celular</label>
                                  </div>
                                  <div className="col-md-6">
                                  {this.state.editMode ? (
                                    <input onChange={this.handleInputChange.bind(this)} name="telefono" value={this.state.telefono ? this.state.telefono : ""} type="text" className="form-control" />
                                  ) : (
                                    <p style={{cursor:'pointer'}} onClick={() => this.setState({editMode: true})}>
                                      {this.state.telefono ? this.state.telefono : "Por favor ingresa tu teléfono celular"}
                                    </p>
                                  )}
                                  </div>
                              </div>
                              {this.state.fechainicio ? (
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Fecha de inicio de su último plan</label>
                                    </div>
                                    <div className="col-md-6">
                                        <p>{this.state.fechainicio}</p>
                                    </div>
                                </div>
                              ) : null}
                              {this.state.user && this.state.user.fechacorte_real ? (
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Fecha de corte</label><br/>
                                    </div>
                                    <div className="col-md-6">
                                        <p>{this.state.fechacorte} <small>Recuerda que puedes entrenar hasta el día {fechacorte}</small></p>
                                    </div>
                                </div>
                              ) : null}

                              <div className="row">
                                  <div className="col-md-12">
                                      <label>Perfil Atlético</label><br/>
                                      {this.state.editMode ? (
                                        <textarea onChange={this.handleInputChange.bind(this)} className="form-control z-depth-1" name="perfilCliente" id="exampleFormControlTextarea6" rows="3" placeholder="Me gusta...">
                                          {this.state.user && this.state.user.perfilCliente ? this.state.user.perfilCliente : ""}
                                        </textarea>
                                      ) : (
                                        <p style={{cursor:'pointer'}} onClick={() => this.setState({editMode: true})}>
                                          {this.state.user && this.state.user.perfilCliente ? this.state.user.perfilCliente : "Queremos conocerte cuéntanos sobre tí, por qué te gusta entrenar? qué tan emocionado estás?..."}
                                        </p>
                                      )}
                                  </div>
                              </div>
                              <div>
                                <br/>
                                {this.state.editMode ? (
                                  <div>
                                    <button onClick={this.handleSubmit.bind(this)} className="btn btn-outline-success">
                                      Editar Perfil
                                    </button>
                                    <button onClick={ () => { this.setState({ editMode:false} ) } } className="btn btn-outline-dark">
                                      Cancelar
                                    </button>
                                  </div>
                                ) : (null
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h5>OBJETIVOS</h5>
                        <div className="card">
                          <div className="card-header">
                            <h6>OBJETIVOS A COTRO PLAZO <button onClick={() => this.setState({modal2: true})} href="#" className="btn btn-outline-success">
                              <i className="fas fa-plus"></i> Agregar
                            </button>
                            </h6>
                          </div>
                          <ul className="list-group list-group-flush">
                            {this.state.user && this.state.user.objetivosCortos && this.state.user.objetivosCortos.map(misObjCortos => (
                              <li className="list-group-item">{misObjCortos.objetivo} <small>Meta: {misObjCortos.fecha}</small>
                                {misObjCortos.checked ? (
                                  <span> <i className="fas btn-outline-success fa-thumbs-up"></i> Objetivo alcanzado</span>
                                ):(
                                  <div>
                                  <button onClick={() => {
                                    let user = Parse.User.current();
                                    let index = this.state.user.objetivosCortos.findIndex((arr)=>{return arr === misObjCortos})
                                    let objetivosCortos = this.state.user.objetivosCortos
                                    objetivosCortos[index]["checked"] = true;
                                    user.set("objetivosCortos", objetivosCortos);
                                     user.save().then(()=>{
                                      this.props.getUserData()
                                     });
                                  }} className="btn btn-outline-success">
                                    <i className="fas fa-check"></i> Objetivo Alcanzado
                                  </button>
                                  <button onClick={() => {
                                    let user = Parse.User.current();
                                    let index = this.state.user.objetivosCortos.findIndex((arr)=>{return arr === misObjCortos})
                                    let objetivosCortos = this.state.user.objetivosCortos
                                    objetivosCortos.splice(index, 1)
                                    user.set("objetivosCortos", objetivosCortos);
                                    user.save().then(()=>{
                                      this.props.getUserData()
                                    });
                                  }} href="#" className="btn btn-outline-danger">
                                    <i className="fas fa-trash-alt"></i> Borrar
                                  </button>
                                  </div>
                                )}<br/>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <Modal open={this.state.modal2} onClose={() => this.setState({modal2: false})} center>
                          <h5>&nbsp;</h5>
                          <div className="form-group shadow-textarea">
                            <label for="exampleFormControlTextarea6">Ingresa un objetivo a corto plazo. Te ayudaremos a alcanzarlo</label>
                            <textarea className="form-control z-depth-1" name="objetivoCorto" onChange={this.handleInputChange.bind(this)} id="exampleFormControlTextarea6" rows="3" placeholder="Ej. Bajar 1 Kg de grasa en 1 mes">
                            </textarea>
                            <label for="exampleFormControlTextarea6">Fecha para la cual deseas alcanzarlo</label><br/>
                            <input name="fechaCorto" value={onemonth.getDate() + "/" + onemonth.getMonth() + "/" + onemonth.getFullYear()} type="text" readonly className="form-control" />
                            <br/>
                            <button onClick={()=>{
                              let user = Parse.User.current();
                              if(this.state.objetivoCorto){
                                if(this.state.user && this.state.user.objetivosCortos){
                                  if(Array.isArray(this.state.user.objetivosCortos)){
                                    let objetivosCortos = this.state.user.objetivosCortos
                                    let fechaobjetivo = new Date();
                                    fechaobjetivo.setMonth(onemonth.getMonth()+1);
                                    console.log(objetivosCortos);
                                    let nuevoObjetivo = {"objetivo": this.state.objetivoCorto, "fecha":fechaobjetivo.toISOString()}
                                    objetivosCortos.push(nuevoObjetivo);
                                    console.log(objetivosCortos);
                                    user.set("objetivosCortos", objetivosCortos);
                                    user.save().then(()=>{
                                      this.props.getUserData()
                                    });
                                  }else{
                                    alert("Hubo un error con la carga de tu objetivo. Comunícate con nosotros")
                                  }
                                }else{
                                  let fechaobjetivo = new Date();
                                  fechaobjetivo.setMonth(onemonth.getMonth()+1);
                                  let nuevoObjetivo = [{"objetivo": this.state.objetivoCorto, "fecha":fechaobjetivo.toISOString()}];
                                  user.set("objetivosCortos", nuevoObjetivo);
                                  user.save().then(()=>{
                                    this.props.getUserData()
                                  });
                                }
                                this.setState({modal2: false})
                              }else{
                                alert("Ingrese su objetivo por favor")
                              }

                            }} type="button" className="btn btn-outline-success">Guardar objetivo</button>
                          </div>
                        </Modal>
                        <br/>
                        <div className="card">
                        <div className="card-header">
                          <h6>OBJETIVOS A LARGO PLAZO <button onClick={() => this.setState({modal3: true})} href="#" className="btn btn-outline-success">
                            <i className="fas fa-plus"></i> Agregar
                          </button>
                          </h6>
                        </div>
                          <ul className="list-group list-group-flush">
                            {this.state.user && this.state.user.objetivosLargos && this.state.user.objetivosLargos.map(misObjLargos => (
                              <li className="list-group-item">{misObjLargos.objetivo} <small>Meta: {misObjLargos.fecha}</small>
                                {misObjLargos.checked ? (
                                  <span> <i className="fas btn-outline-success fa-thumbs-up"></i> Objetivo alcanzado</span>
                                ):(
                                  <div>
                                  <button onClick={() => {
                                    let user = Parse.User.current();
                                    let index = this.state.user.objetivosLargos.findIndex((arr)=>{return arr === misObjLargos})
                                    let objetivosCortos = this.state.user.objetivosLargos
                                    objetivosCortos[index]["checked"] = true;
                                    user.set("objetivosLargos", objetivosCortos);
                                     user.save().then(()=>{
                                      this.props.getUserData()
                                     });
                                  }} className="btn btn-outline-success">
                                    <i className="fas fa-check"></i> Objetivo Alcanzado
                                  </button>
                                  <button onClick={() => {
                                    let user = Parse.User.current();
                                    let index = this.state.user.objetivosLargos.findIndex((arr)=>{return arr === misObjLargos})
                                    let objetivosCortos = this.state.user.objetivosLargos
                                    objetivosCortos.splice(index, 1)
                                    user.set("objetivosLargos", objetivosCortos);
                                    user.save().then(()=>{
                                      this.props.getUserData()
                                    });
                                  }} href="#" className="btn btn-outline-danger">
                                    <i className="fas fa-trash-alt"></i> Borrar
                                  </button>
                                  </div>
                                )}<br/>
                              </li>
                            ))}
                          </ul>
                          <Modal open={this.state.modal3} onClose={() => this.setState({modal3: false})} center>
                            <h5>&nbsp;</h5>
                            <div className="form-group shadow-textarea">
                              <label for="exampleFormControlTextarea6">Ingresa un objetivo a largo plazo. Te ayudaremos a alcanzarlo</label>
                              <textarea className="form-control z-depth-1" name="objetivoLargo" onChange={this.handleInputChange.bind(this)} id="exampleFormControlTextarea6" rows="3" placeholder="Ej. Hacer 20 burpees en 3 meses">
                              </textarea>
                              <label for="exampleFormControlTextarea6">Fecha para la cual deseas alcanzarlo</label><br/>
                              <input name="fechaCorto" value={threemonth.getDate() + "/" + threemonth.getMonth() + "/" + threemonth.getFullYear()} type="text" readonly className="form-control" />
                              <br/>
                              <button onClick={()=>{
                                let user = Parse.User.current();
                                if(this.state.objetivoLargo){
                                  if(this.state.user && this.state.user.objetivosLargos){
                                    if(Array.isArray(this.state.user.objetivosLargos)){
                                      let objetivosCortos = this.state.user.objetivosLargos
                                      let fechaobjetivo = new Date();
                                      fechaobjetivo.setMonth(onemonth.getMonth()+3);
                                      console.log(objetivosCortos);
                                      let nuevoObjetivo = {"objetivo": this.state.objetivoLargo, "fecha":fechaobjetivo.toISOString()}
                                      objetivosCortos.push(nuevoObjetivo);
                                      console.log(objetivosCortos);
                                      user.set("objetivosLargos", objetivosCortos);
                                      user.save().then(()=>{
                                        this.props.getUserData()
                                      });
                                    }else{
                                      alert("Hubo un error con la carga de tu objetivo. Comunícate con nosotros")
                                    }
                                  }else{
                                    let fechaobjetivo = new Date();
                                    fechaobjetivo.setMonth(onemonth.getMonth()+3);
                                    let nuevoObjetivo = [{"objetivo": this.state.objetivoLargo, "fecha":fechaobjetivo.toISOString()}];
                                    user.set("objetivosLargos", nuevoObjetivo);
                                    user.save().then(()=>{
                                      this.props.getUserData()
                                    });
                                  }
                                  this.setState({modal3: false})
                                }else{
                                  alert("Ingrese su objetivo por favor")
                                }

                              }} type="button" className="btn btn-outline-success">Guardar objetivo</button>
                            </div>
                          </Modal>
                        </div>


                    </div>
                </div>
          </div>
        </div>
    );
  }
}
