import React from 'react';
import logoBlanco from "./Images/logoBlancox3.png";
import MasculinoAvatar from './Images/Masculino.svg';
import FemeninoAvatar from './Images/Femenino.svg';
import addpic from './Images/addpic.svg';
import Loading from './Images/loading.gif';
import './App.css';
import { getProgresos, getAlimentos, getTips } from "./actions/AgendaHelper";
import ReactHtmlParser from 'react-html-parser';
import Modal from 'react-responsive-modal';
import Parse from "parse";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Dropbox} from 'dropbox'
Parse.initialize("myAppIdVesCr0b51");
Parse.serverURL = "https://miembros.vescolombia.com.co/parse";

const modalstyle2 = {
  modal: {
    'max-width': "90%",
    'width': "90vw",
    'text-align': "justify",
    'padding': 20
  }
};

const accessToken = 'DC3q4Q6sIwAAAAAAAAAAC6GtnNTJCqCERh-ap2KrdQSiDkBJwP-ROA7a7KTGd7Qc';
const dbx = new Dropbox({
  accessToken,
  fetch
});

export default class Progresos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      asistencias: [],
      myranking: 0,
      loading: false,
      modal: false,
      modal2: false,
      modal3: false,
      startDate: new Date(),
      recetaTitle: "",
      recetaBody: "",
      activeItemIndex: 0,
      tips: [],
      progresos: [],
      modalstyle: null,
      body: null,
      addpic: addpic,
      loadingAvatar: Loading,
    };
    this.verTip = this.verTip.bind(this)
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });
  }

  cerrarSesion() {
    Parse.User.logOut().then(
      function(success) {
        var currentUser = Parse.User.current();
        if (currentUser) {
          //I have current user always.
        }
        window.location.href = "https://miembros.vescolombia.com.co";
      },
      function(error) {
        alert(error);
        window.location.href = "https://miembros.vescolombia.com.co";
      }
    );
  }
  verTip(tip){
    this.setState({
      modal3: true,
      recetaTitle: tip.title,
      modalstyle: modalstyle2,
      recetaBody: ReactHtmlParser(tip.cuerpo.replace(/\\"/g, '"'))
    });
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name);
    console.log(value);
    this.setState({
      [name]: value
    });
  }

  componentWillReceiveProps(nextProps){
    let user = nextProps.user;
    this.setState({
      fullname: user.fullname,
      email: user.email,
      nit: user.nit,
      telefono: user.telefono,
      fechainicio: user.fechainicio,
      fechacorte: user.fechacorte,
      objetivo: user.objetivo,
      user: user
    })
  }

  componentDidMount() {
    if(this.props && this.props.user){
      let user = this.props.user;
      this.setState({
        fullname: user.fullname,
        email: user.email,
        nit: user.nit,
        telefono: user.telefono,
        fechainicio: user.fechainicio,
        fechacorte: user.fechacorte,
        objetivo: user.objetivo,
        user: user
      })
    }
    // this.setState({
    //   email: user.email,
    //   nit: user.nit,
    //   telefono: user.telefono,
    //   fechainicio: user.fechainicio,
    //   fechacorte: user.fechacorte,
    //   objetivo: user.objetivo,
    //   fullname: user.fullname,
    //   user: user,
    //   sexo: user.sexo,
    // })



    getAlimentos().then(results => {
      let alimentos = JSON.parse(results);
      let carbos = [], protes = [], fats = [];
      for (var i = 0; i < alimentos.results.length; i++) {
        let alimento = alimentos.results[i];
        if(alimento.macronutriente.includes("Carbohidratos"))
          if(alimento.ig==="Bajo IG")
            carbos.push('<span class="success">'+alimento.nombre+'</span>')
          else if (alimento.ig==="Medio IG")
            carbos.push('<span class="warning">'+alimento.nombre+'</span>')
          else
            carbos.push('<span class="danger">'+alimento.nombre+'</span>')

        if(alimento.macronutriente.includes("Proteinas"))
          protes.push(alimento.nombre)

        if(alimento.macronutriente.includes("Grasas"))
          fats.push(alimento.nombre)
      }
      this.setState({
        carbos: carbos.join(', '),
        protes: protes.join(', '),
        fats: fats.join(', '),
        alimentos: alimentos
      })
    })
    getTips(null).then(tips => {
      let tipsAr = JSON.parse(tips);
      this.setState({ tips: tipsAr.results})
    })

    getProgresos().then(results => {
      let result = JSON.parse(results);
      this.setState({
        loading: false,
        progresos: result.results
      });
    });

  }

  handleFileChange(event) {
    const self = this;
    var fileInput = document.getElementById('fotoPerfil');
    var reader = new FileReader();
    reader.onload = function (e) {
        // document.getElementById('fotoPerfil').setAttribute('src', e.target.result);
        self.setState({
          addpic: e.target.result
        })
    }
    reader.readAsDataURL(fileInput.files[0]);
    return false;
  }

  handleSubmit(event){
    this.setState({
      loading: true
    });
    const self = this;
    var fileInput = document.getElementById('fotoPerfil');
    var file = fileInput.files[0];
    var fecha = this.state.startDate;
    var fechaRegistro = fecha.getFullYear() + "/" + fecha.getMonth().parseInt() + 1 + "/" + fecha.getDate();
    console.log("entro 0");
    dbx.filesUpload({path: '/' + file.name, contents: file, mode: 'overwrite'})
    .then(function(response) {
      console.log(response.path_display);
      dbx.sharingCreateSharedLinkWithSettings({path: response.path_display})
      .then(function(responselink) {
          console.log("entro");
         let userObj = Parse.User.current();
         userObj = userObj._toFullJSON();

         var xhttp = new XMLHttpRequest();
         xhttp.open(
           "POST",
           "https://miembros.vescolombia.com.co/parse/classes/Progresos",
           true);
         xhttp.setRequestHeader("X-Parse-Application-Id", "myAppIdVesCr0b51");
         xhttp.setRequestHeader("X-Parse-Master-Key", "v3sm4st3rk3y");
         xhttp.setRequestHeader("X-Parse-Session-Token", userObj.sessionToken);
         xhttp.onload = function() {
           alert("Registro ingresado con éxito");
           getProgresos().then(results => {
             let result = JSON.parse(results);
             self.setState({
               loading: false,
               progresos: result.results
             });
           });
         };
         //sessionToken
         xhttp.send(
           '{"fecha":"' +
             fechaRegistro +
             '", "body":"' +
             self.state.body +
             '", "userId":"' +
             userObj.objectId +
             '", "img":"' +
             responselink.url.replace('dl=0', 'raw=1') +
             '"}'
         );

       })
       .catch(function(error) {
         console.log(error);
       });
    })
    .catch(function(error) {
      console.error(error);
    });
  }

  render() {


    let fechacorte = this.state.user && this.state.user.fechacorte_real ? this.state.user.fechacorte_real.iso : "";
    let addpic = this.state.addpic
    const fechaSplited = fechacorte.split("T");
    const fechaArray = fechaSplited[0].split("-");
    fechacorte = fechaArray[2] + "/" + parseInt(fechaArray[1]) + "/" + fechaArray[0];

    let avatar = MasculinoAvatar

    if(this.state.sexo === "Femenino")
      avatar = FemeninoAvatar

    if(this.state.user && this.state.user.avatar)
      avatar = this.state.user.avatar

      if(this.state.loading){
        addpic = this.state.loadingAvatar
      }

    return (
      <div id="page-wrap">
        <header className="App-header">
          <img src={logoBlanco} className="App-logo" alt="logo" />
        </header>
        <div className="container emp-profile">

                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-img">
                            <img src={avatar} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head">
                          <h5>
                              {this.state.fullname}
                          </h5>
                          <button onClick={this.cerrarSesion.bind(this)} className="btn btn-outline-success">
                            Cerrar Sesión
                          </button>
                          <br/>
                          <h6 style={{cursor:'pointer'}} onClick={() => this.setState({modal: true})}>
                                {this.state.objetivo && this.state.objetivo.length ? this.state.objetivo : "No has ingresado tu objetivo general"}
                          </h6>
                          <Modal open={this.state.modal} onClose={() => this.setState({modal: false})} center>
                            <h5>Objetivo General</h5>
                            <div className="form-group shadow-textarea">
                              <label for="exampleFormControlTextarea6">Ingresa una descripción de tu objetivo general con VES</label>
                              <textarea className="form-control z-depth-1" name="objetivo" onChange={this.handleInputChange.bind(this)} id="exampleFormControlTextarea6" rows="3" placeholder="Mejorar mi salud...">
                                {this.state.objetivo}
                              </textarea>
                              <br/>
                              <button onClick={()=>{
                                let user = Parse.User.current();
                                if(this.state.objetivo.length){
                                  user.set("objetivo", this.state.objetivo);
                                  user.save().then(()=>{
                                    this.props.getUserData()
                                  });
                                  this.setState({modal: false})
                                }else{
                                  alert("Ingrese su objetivo por favor")
                                }

                              }} type="button" className="btn btn-outline-success">Guardar objetivo</button>
                            </div>
                          </Modal>
                          <p className="proile-rating">NÚMERO DE ENTRENAMIENTOS REALIZADOS : <span>{this.state.asistencias.length}</span></p>
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item">
                                  <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Progresos</a>
                              </li>
                          </ul>
                        </div>
                        <div className="col-md-12">
                          <p className="category">
                            LLevar un Log de tus progresos te ayudará a mantenerte enfocado en tus metas. Ver tus progresos además te animará a nunca rendirte.
                          </p>
                        </div>
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>
                <div className="col-md-6 offset-md-3 text-center">
                  <h5 className="card-title">Sube un registro de tu progreso</h5>
                  <div style={{cursor:'pointer'}} onClick = { () => { document.getElementById("fotoPerfil").click() }} className="profile-img">
                      <img width="100px" style={{maxWidth: 100}} src={addpic} alt=""/>
                      <input className="fotoFile" id="fotoPerfil" type="file" name="fotoPerfil" onChange={this.handleFileChange.bind(this)} />
                  </div>
                  <p>Haga clic en la foto para cambiarla</p>
                  <div className="form-group">
                    <DatePicker
                      className="form-control"
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                      dateFormat="dd/MM/yyyy"
                      placeholder="Fecha del registro"
                    />
                  </div>
                  <div className="form-group">
                  <textarea onChange={this.handleInputChange.bind(this)} className="form-control z-depth-1" name="body" id="exampleFormControlTextarea6" rows="3" placeholder="Texto para el registro. Ej: Talla de pantalón = N ...">
                  </textarea>
                  <button onClick={ this.handleSubmit.bind(this) } className="btn btn-outline-success" >Guardar</button>
                  </div>
                </div>
                <div className="row">
                    {this.state.progresos.map(receta => (
                      <div className="col-md-4" key={receta.objectId}>
                        <div className="card">
                          <img src={receta.img ? receta.img : null} className="card-img-top" alt={receta.title} />
                          <div className="card-body">
                            <h5 className="card-title">{receta.fecha}</h5>
                            <p>
                              {receta.body}
                            </p>
                          </div>
                        </div>
                        <br/>
                      </div>
                    ))}
                  <br/>
                </div>
          </div>
          <Modal styles={this.state.modalstyle} open={this.state.modal3} onClose={() => this.setState({modal3: false})} center>
          <h5 className="card-title">{this.state.recetaTitle}</h5>
          <br/>
          {this.state.recetaBody}
          </Modal>
        </div>

    );
  }
}
